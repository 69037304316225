import * as React from "react";
import clsx from "clsx";
import { observer } from "mobx-react";
import { Link, Navigate } from "react-router-dom";
import { Edge, Node } from "@xyflow/react";

import SectionFlowCreatorStep from "../../SectionFlowCreatorWizard/SectionFlowCreatorStep";
import SectionFlowDetailStep from "../../SectionFlowCreatorWizard/SectionFlowDetailStep";
import { DashboardSection } from "../../../models/DashboardSection";
import {
  createDashboardSection,
  deleteDashboardSection,
  getDashboardSection,
  saveDashboardSection,
} from "../../../../helpers/api";
import stores from "../../../stores";

interface State {
  tab: string;
  navigate: boolean;
  selectedSection: DashboardSection | undefined;
  nodes: Node[];
  edges: Edge[];
}

interface Props {
  selectedSectionId: number | undefined;
}
const TABS = ["flow-creator", "detail-flow"];

@observer
class CreateNewSectionFlow extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      tab: TABS[0],
      navigate: false,
      nodes: [],
      edges: [],
      selectedSection: undefined,
    };
  }

  componentWillMount(): void {
    const { selectedSectionId } = this.props;

    if (selectedSectionId) {
      this.getSelectedSection(selectedSectionId);
    }
  }

  getSelectedSection = async (selectedSectionId: number) => {
    const selectedSection = await getDashboardSection(selectedSectionId);

    if (selectedSection) {
      this.setState({
        selectedSection,
        nodes: selectedSection.node_objects_list,
        edges: selectedSection.edge_objects_list,
      });
    }
  };

  setTab = (tabName: string) => {
    this.setState({ tab: tabName });
  };

  handleNext = () => {
    const currentIndex = TABS.indexOf(this.state.tab);
    if (currentIndex < TABS.length - 1) {
      this.setTab(TABS[currentIndex + 1]);
    }
  };

  handleBack = () => {
    const currentIndex = TABS.indexOf(this.state.tab);
    if (currentIndex > 0) {
      this.setTab(TABS[currentIndex - 1]);
    }
  };

  handleSave = async (section: DashboardSection) => {
    const { selectedSection } = this.state;
    stores.wizardStore.isSectionFlowLoading = true;

    if (selectedSection) {
      await deleteDashboardSection(selectedSection.id);
    }

    const newSection = await createDashboardSection(section);

    if (newSection) {
      await saveDashboardSection(newSection);
    }
    stores.wizardStore.isSectionFlowLoading = false;

    this.setState({ navigate: true });
  };

  handleNodesChange = (nodes: any[]) => {
    this.setState({ nodes });
  };

  handleEdgesChange = (edges: any[]) => {
    this.setState({ edges });
  };

  render() {
    const { tab, navigate, nodes, edges, selectedSection } = this.state;

    if (navigate) {
      return <Navigate to="/the-chief-workspace" />;
    }

    return (
      <div className="d-flex flex-column align-items-center min-vh-100">
        <div
          className="mb-3 mt-10 d-flex justify-content-between align-items-center"
          style={{
            width: "85%",
          }}
        >
          <div>
            <div className="fw-bold fs-2">
              {selectedSection ? "Editing Flow" : "Creating Flow"}
            </div>
          </div>
          <Link
            to="/the-chief-workspace"
            className={"text-primary fw-bold "}
            onClick={(event) => {
              if (stores.wizardStore.isSectionFlowLoading) {
                event.preventDefault();
              }
            }}
          >
            <button
              className="btn btn-primary"
              disabled={stores.wizardStore.isSectionFlowLoading}
            >
              Cancel
            </button>
          </Link>
        </div>

        <div className="card w-100">
          <div className="card-header justify-content-center overflow-auto">
            <ul
              className="nav nav-stretch nav-line-tabs fw-bold border-transparent flex-nowrap justify-content-center"
              role="tablist"
            >
              <li className="nav-item">
                <button
                  className={clsx("nav-link cursor-default", {
                    active: tab === "flow-creator",
                    "text-active-primary": tab === "flow-creator",
                  })}
                  role="tab"
                  style={{
                    pointerEvents: "none",
                    color: tab === "flow-creator" ? "#00ff00" : "inherit",
                  }}
                  disabled
                >
                  <p className="fs-5 px-15 pt-10">{"Flow Creator"}</p>
                </button>
              </li>
              <li className="nav-item">
                <button
                  className={clsx("nav-link cursor-default", {
                    active: tab === "detail-flow",
                    "text-active-primary": tab === "detail-flow",
                  })}
                  role="tab"
                  style={{
                    pointerEvents: "none",
                    color: tab === "detail-flow" ? "#00ff00" : "inherit",
                  }}
                  disabled
                >
                  <p className="fs-5 px-15 pt-10">{"Detail Flow"}</p>
                </button>
              </li>
            </ul>
          </div>
          <div className="card-body p-0">
            <div className="tab-content">
              <div
                className={clsx("tab-pane", {
                  active: tab === "flow-creator",
                })}
              >
                <div className="row mb-10">
                  <SectionFlowCreatorStep
                    onNext={this.handleNext}
                    onBack={this.handleBack}
                    onNodesChange={this.handleNodesChange}
                    onEdgesChange={this.handleEdgesChange}
                    selectedSection={selectedSection}
                  />
                </div>
              </div>

              <div
                className={clsx("tab-pane", {
                  active: tab === "detail-flow",
                })}
              >
                <div className="row mb-10">
                  <SectionFlowDetailStep
                    onBack={this.handleBack}
                    onSave={this.handleSave}
                    nodes={nodes}
                    edges={edges}
                    isEditMode={selectedSection ? true : false}
                    selectedSection={selectedSection}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

export default CreateNewSectionFlow;
