// @flow

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { Company } from "../../app/models/Company";
import { CompanyIndexStatus } from "../Enums";

export default async function createCompany(
  user_id: number,
  department_id: number,
  name: string,
  logo: string | undefined,
  teamSize: string | undefined,
  industry: string | undefined,
  plan: string | undefined,
  timezone: string,
  available_avatar_count: number,
  website: string | undefined
): Promise<Company> {
  return new Promise(async (resolve, reject) => {
    const client = ApolloClientHelper.getApolloClient();

    const mutation = gql`
      mutation {
        createCompany(
          user_id: ${user_id}
          department_id: ${department_id}
          name: "${name}"
          logo: "${logo}"
          teamSize: "${teamSize}"
          industry: "${industry}"
          plan: "${plan}"
          created_at: ${Date.now()}
          index_status: "${CompanyIndexStatus.Failed}"
          index_updated_at: 0
          timezone: "${timezone}"
          available_avatar_count: ${available_avatar_count}
          is_fine_tuned_model_active: 0
          website: "${website}"
        ) {
          id
          user_id
          name
          logo
          created_at
          teamSize
          industry
          plan
          index_status
          index_updated_at
          available_avatar_count
          is_fine_tuned_model_active
          fine_tuning_progress
          timezone
          website
        }
      }
    `;

    client
      .mutate({ mutation })
      .then((createCompanyMutationResult: any) => {
        const company: Company = get(
          createCompanyMutationResult,
          "data.createCompany",
          null
        );

        if (company) {
          resolve(company);
        } else {
          reject();
        }
      })
      .catch((error: any) => {
        console.error(error);

        reject();
      });
  });
}
