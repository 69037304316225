import { ConnectionParam } from "../app/models/ConnectionParam";
import { DashboardSection } from "../app/models/DashboardSection";
import { DocumentMessage } from "../app/models/DocumentMessage";
import { SectionKpiQuestionReport } from "../app/models/SectionKpiQuestionReport";
import i18n from "../i18n";

/**
 * Lightens a given hex color by a specified percentage.
 * @param {string | undefined} color - The hex color code to lighten.
 * @param {number} percent - The percentage to lighten the color by.
 * @returns {string} - The lightened color as a hex string.
 */
const lightenColor = (color: string | undefined, percent: number): string => {
  if (!color) return "#ffffff"; // Default to white if color is undefined
  const num = parseInt(color.replace("#", ""), 16);
  if (isNaN(num)) return "#ffffff"; // Default to white if color parsing fails
  const amt = Math.round(2.55 * percent),
    R = (num >> 16) + amt,
    G = ((num >> 8) & 0x00ff) + amt,
    B = (num & 0x0000ff) + amt;
  return (
    "#" +
    (
      0x1000000 +
      (R < 255 ? (R < 1 ? 0 : R) : 255) * 0x10000 +
      (G < 255 ? (G < 1 ? 0 : G) : 255) * 0x100 +
      (B < 255 ? (B < 1 ? 0 : B) : 255)
    )
      .toString(16)
      .slice(1)
  );
};

const formatDate = (dateInput: number | string | undefined): string => {
  if (!dateInput) return "Unknown Date";

  let date: Date;

  if (typeof dateInput === "number") {
    date =
      dateInput > 9999999999 ? new Date(dateInput) : new Date(dateInput * 1000);
  } else {
    const isoDateString = dateInput.replace(" ", "T");
    date = new Date(isoDateString);
  }

  if (isNaN(date.getTime())) {
    return "Invalid Date";
  }

  return date.toLocaleDateString(undefined, {
    year: "numeric",
    month: "short",
    day: "numeric",
  });
};

const nowDate = () => {
  const date = new Date();

  let day: number = date.getDate();
  let month: number = date.getMonth() + 1;
  let year: number = date.getFullYear();
  let currentDate: string = `${day}.${month}.${year}`;

  return currentDate;
};

const getPastTimeInfo = (prevTime: number) => {
  var msPerMinute = 60 * 1000;
  var msPerHour = msPerMinute * 60;
  var msPerDay = msPerHour * 24;
  var msPerMonth = msPerDay * 30;
  var msPerYear = msPerDay * 365;

  var elapsed = new Date().getTime() - prevTime;

  if (elapsed < msPerMinute) {
    return `${i18n.formatString(
      i18n.common.ago,
      String(Math.round(elapsed / 1000)),
      i18n.common.seconds
    )}`;
  } else if (elapsed < msPerHour) {
    return `${i18n.formatString(
      i18n.common.ago,
      String(Math.round(elapsed / msPerMinute)),
      i18n.common.minutes
    )}`;
  } else if (elapsed < msPerDay) {
    return `${i18n.formatString(
      i18n.common.ago,
      String(Math.round(elapsed / msPerHour)),
      i18n.common.hours
    )}`;
  } else if (elapsed < msPerMonth) {
    return `${i18n.formatString(
      i18n.common.ago,
      String(Math.round(elapsed / msPerDay)),
      i18n.common.days
    )}`;
  } else if (elapsed < msPerYear) {
    return `${i18n.formatString(
      i18n.common.ago,
      String(Math.round(elapsed / msPerMonth)),
      i18n.common.months
    )}`;
  } else {
    return `${i18n.formatString(
      i18n.common.ago,
      String(Math.round(elapsed / msPerYear)),
      i18n.common.years
    )}`;
  }
};

const compareVersions = (v1: string, v2: string) => {
  const parts1 = v1.split(".").map(Number);
  const parts2 = v2.split(".").map(Number);

  if (parts1.length !== 3 || parts2.length !== 3) {
    throw new Error("Invalid version format. Expected format: 'X.X.X'");
  }

  for (let i = 0; i < 3; i++) {
    if (parts1[i] > parts2[i]) return false;
    if (parts1[i] < parts2[i]) return true;
  }

  return false;
};

const isValidUrl = (url: string) => {
  const urlRegex = /^(ftp|http|https):\/\/[^ "]+$/;
  return urlRegex.test(url);
};

const formatChatHistory = (messages: DocumentMessage[]) => {
  let historyString = "";
  // 1 token 4 chars in English
  let historyCharLimit = 20000;
  let totalCharCount = 0;
  let messageCount = 1;

  while (totalCharCount < historyCharLimit && messages.length >= messageCount) {
    const message = messages[messages.length - messageCount];

    const newInteraction = `Human: ${message.message}\nAI: ${message.message_reply}`;
    historyString = `${historyString}\n\n${newInteraction}`;

    totalCharCount += historyString.length;
    messageCount += 1;
  }

  return historyString;
};

const formatChatHistoryWithSectionKpiQuestionReport = (
  section: DashboardSection,
  reports: SectionKpiQuestionReport[]
) => {
  let historyString = "";
  // 1 token 4 chars in English
  let historyCharLimit = 20000;
  let totalCharCount = 0;
  let reportCount = 1;

  const questionNode = section.node_objects_list.find(
    (node) => node.type === "questionNode"
  );
  const questionText: any = questionNode?.data.text;

  while (totalCharCount < historyCharLimit && reports.length >= reportCount) {
    const report = reports[reports.length - reportCount];

    const newInteraction = `Human: ${questionText}\nAI: ${report.report}`;
    historyString = `${historyString}\n\n${newInteraction}`;

    totalCharCount += historyString.length;
    reportCount += 1;
  }

  return historyString;
};

const extractFilenameAndExtension = (path: string) => {
  let filenameWithExtension = path.substring(path.lastIndexOf("/") + 1);
  let extension = filenameWithExtension.substring(
    filenameWithExtension.lastIndexOf(".") + 1
  );
  let filename = filenameWithExtension.substring(
    0,
    filenameWithExtension.lastIndexOf(".")
  );
  let capitalizedExtension =
    extension.charAt(0).toUpperCase() + extension.slice(1);

  return {
    filename: filename,
    extension: capitalizedExtension,
  };
};

const transformStringToConnectionParam = (
  params: any
): ConnectionParam[] | undefined => {
  try {
    return JSON.parse(decodeURIComponent(params)) as ConnectionParam[];
  } catch (error) {
    return undefined;
  }
};

const transformArrayToObject = (
  params: ConnectionParam[]
): { [key: string]: string } => {
  return params.reduce<{ [key: string]: string }>((param, current) => {
    param[current.key] = current.info;
    return param;
  }, {});
};

const removeMentions = (input: string): string => {
  const pattern = /@\[[^\]]+\]\(\d+\)\s*/g;
  return input.replace(pattern, "").trim();
};

const sanitizeAndStoreMentions = (input: string): string => {
  const pattern = /@\[([^\]]+)\]\(\d+\)/g;

  const cleanedString = input.replace(pattern, (match, mentionName) => {
    return mentionName;
  });

  return cleanedString.trim();
};

const getTrimmedFileName = (input: string): string => {
  let cleanMessage = input;
  cleanMessage = cleanMessage.replace(/[\n\r\t]/g, " ");
  cleanMessage = cleanMessage.replace(/\s+/g, " ");
  const firstFourWords = cleanMessage.split(" ").slice(0, 20).join(" ");
  const suffix = "...";
  const maxChar = 80;
  let trimmedFirstFourWords =
    firstFourWords.length > maxChar
      ? firstFourWords.substring(0, maxChar)
      : firstFourWords;

  trimmedFirstFourWords += suffix;

  return trimmedFirstFourWords;
};

const truncateFileName = (fileName: string): string => {
  const maxLength = 12;
  const extensionIndex = fileName.lastIndexOf(".");
  const extension = fileName.substring(extensionIndex);

  if (fileName.length <= maxLength) {
    return fileName;
  }

  const startPartLength = Math.ceil((maxLength - 3) / 2);
  const endPartLength = maxLength - 3 - startPartLength;

  const startPart = fileName.substring(0, startPartLength);
  const endPart = fileName.substring(
    fileName.length - endPartLength - extension.length
  );

  return `${startPart}...${endPart}${extension}`;
};

const decodeMessage = (input: string): string => {
  try {
    let decodedMessage = decodeURIComponent(input);
    return decodedMessage;
  } catch (error) {
    try {
      const reEncodedMessage = encodeURIComponent(input);
      let decodedMessage = decodeURIComponent(reEncodedMessage);
      return decodedMessage;
    } catch (error) {
      console.error("Decode message error: ", error);

      return "";
    }
  }
};

const convertTimestampToDate = (timestamp: number) => {
  let date = new Date(timestamp);

  let year = date.getFullYear();
  let month = String(date.getMonth() + 1).padStart(2, "0");
  let day = String(date.getDate()).padStart(2, "0");

  let formattedDate = `${year}-${month}-${day} 00:00:00`;

  return formattedDate;
};

const calculatePriceForTokens = (
  tokenLimit: number,
  tokenCount: number,
  perPrice: number
) => {
  const tokenRate = perPrice / tokenLimit;
  const roundedTokens = Math.ceil(tokenCount / tokenLimit) * tokenLimit;

  const price = roundedTokens * tokenRate;

  return price.toFixed(2);
};

const completeArrayFromString = (arrayString: string): string[] => {
  try {
    if (!arrayString.endsWith("]")) {
      arrayString += '"]';
    }
    if (!arrayString.startsWith("[")) {
      arrayString = '["' + arrayString;
    }

    if (arrayString.endsWith(",")) {
      arrayString = arrayString.slice(0, -1) + '"]';
    }

    // eslint-disable-next-line no-useless-escape
    arrayString = arrayString.replace(/,\s*"?([^\"]+)$/g, ', "$1"');

    const array = JSON.parse(arrayString);
    const completedArray = array.filter(
      (item: null | undefined) => item != null && item !== undefined
    );

    return completedArray;
  } catch (error) {
    return [];
  }
};

const loadingMessages = [
  "Analyzing your request, please hold on!",
  "We're looking into it, this might take a moment.",
  "Thank you for your patience, this process may take a little while.",
  "Hold tight! We're diving deeper into the details.",
  "Processing your request, please don't leave just yet.",
  "This task requires a bit of time, we appreciate your understanding.",
  "We're exploring thoroughly, hang in there!",
  "Good things take time! We're working on it.",
  "Almost there! Your request is being processed.",
  "Taking a closer look, thank you for waiting!",
  "We're carefully reviewing your input, this won't take long.",
  "Hang tight, we're giving this our full attention!",
  "Your request is important to us, please be patient.",
  "Working through the details, thank you for bearing with us!",
  "We're on it! This might require a few moments.",
  "Deep diving into your request, stay tuned!",
  "This process is complex, but we're making progress.",
  "We're committed to getting this right, thanks for waiting!",
  "Just a moment more, we're finalizing your request.",
  "Your patience means the world to us, we're almost done!",
];

export const getRandomLoadingMessage = (): string => {
  const randomIndex = Math.floor(Math.random() * loadingMessages.length);
  return loadingMessages[randomIndex];
};

const parseNodesAndEdgesString = (jsonString: string) => {
  let parsedData: any = decodeURIComponent(jsonString);
  while (typeof parsedData === "string") {
    try {
      parsedData = JSON.parse(parsedData);
    } catch (e) {
      break;
    }
  }
  return parsedData;
};

export const getGreetingMessage = (): string => {
  const now = new Date();
  const hour = now.getHours();
  const day = now.getDay(); // 0: Sunday, 6: Saturday
  const isWeekend = day === 0 || day === 6;
  let messages: string[] = [];

  if (hour >= 5 && hour < 12) {
    // Early hours (assumed as morning hours without explicitly saying "morning")
    if (isWeekend) {
      messages = [
        "Hello! I hope your weekend is off to a lovely start and that everything is unfolding gently. How can I help you?",
        "Hello! I trust you're enjoying a peaceful start to your weekend. How's your day shaping up so far? How can I help you?",
        "Hello! Wishing you a delightful beginning to your weekend—how is your day going? How can I help you?",
      ];
    } else {
      messages = [
        "Hello! I hope your day is starting on a bright note. How is everything unfolding for you? How can I help you?",
        "Hello! I trust you had a smooth start to your day. How's your day coming along so far? How can I help you?",
        "Hello! Wishing you a pleasant start to your day. How are things going for you this morning? How can I help you?",
      ];
    }
  } else if (hour >= 12 && hour < 17) {
    // Midday hours
    if (isWeekend) {
      messages = [
        "Hello! I hope you're enjoying a relaxed weekend. How's your day going so far? How can I help you?",
        "Hello! I trust your weekend is treating you kindly today. How is your day unfolding? How can I help you?",
        "Hello! Wishing you a pleasant day during your weekend. How are things going so far? How can I help you?",
      ];
    } else {
      messages = [
        "Hello! I hope your day is moving along nicely. How's everything on your end? How can I help you?",
        "Hello! I trust your day is unfolding well. How are you finding your afternoon? How can I help you?",
        "Hello! Wishing you a smooth day so far. How are things going for you this afternoon? How can I help you?",
      ];
    }
  } else if (hour >= 17 && hour < 21) {
    // Late day hours
    if (isWeekend) {
      messages = [
        "Hello! I hope your weekend has been wonderful. How is your evening shaping up? How can I help you?",
        "Hello! I trust you've had a lovely weekend so far. How is your day winding down? How can I help you?",
        "Hello! Wishing you a relaxing end to your weekend day. How's your evening going? How can I help you?",
      ];
    } else {
      messages = [
        "Hello! I hope you had a fulfilling day. How is your evening coming along? How can I help you?",
        "Hello! I trust your day went well. How are you feeling as the day winds down? How can I help you?",
        "Hello! Wishing you a calm end to your day. How is your evening treating you? How can I help you?",
      ];
    }
  } else {
    // Late night/early morning hours
    if (isWeekend) {
      messages = [
        "Hello! I hope you enjoyed a wonderful day this weekend. How are you winding down tonight? How can I help you?",
        "Hello! I trust your weekend was delightful. How's your night going so far? How can I help you?",
        "Hello! Wishing you a peaceful close to your weekend day. How are you doing this evening? How can I help you?",
      ];
    } else {
      messages = [
        "Hello! I hope you had a good day. How are you spending your night? How can I help you?",
        "Hello! I trust your day went well. How's your night shaping up? How can I help you?",
        "Hello! Wishing you a restful night after a busy day. How are things going for you this evening? How can I help you?",
      ];
    }
  }

  return messages[Math.floor(Math.random() * messages.length)];
};

// eslint-disable-next-line import/no-anonymous-default-export
export default {
  nowDate,
  getPastTimeInfo,
  compareVersions,
  isValidUrl,
  formatChatHistory,
  formatChatHistoryWithSectionKpiQuestionReport,
  extractFilenameAndExtension,
  transformArrayToObject,
  removeMentions,
  sanitizeAndStoreMentions,
  getTrimmedFileName,
  truncateFileName,
  transformStringToConnectionParam,
  lightenColor,
  decodeMessage,
  convertTimestampToDate,
  calculatePriceForTokens,
  completeArrayFromString,
  formatDate,
  getRandomLoadingMessage,
  parseNodesAndEdgesString,
  getGreetingMessage,
};
