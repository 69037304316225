import { action, observable, when } from "mobx";
import { toast } from "react-toastify";
import {
  checkNangoAppCredentials,
  createCompanyApp,
  createCompanyUserApp,
  deleteAppData,
  deleteCompanyApp,
  deleteCompanyUserApp,
  getAppDepartments,
  getApps,
  getCompanyApps,
  updateCompanyApp,
} from "../../helpers/api";
import { CompanyApp } from "../models/CompanyApp";
import {
  CompanyAppStatus,
  CompanyUserAppStatus,
  UserActionLogStatus,
} from "../../helpers/Enums";
import { CompanyUser } from "../models/CompanyUser";
import { App } from "../models/App";
import { CompanyUserApp } from "../models/CompanyUserApp";
import { AppDepartment } from "../models/AppDepartment";
import i18n from "../../i18n";
import syncAppData from "../../helpers/api/syncAppData";
import syncSelectedFiles from "../../helpers/api/syncSelectedFiles";

import SocketHelper from "../../helpers/SocketHelper";
import RootStore from "./RootStore";
import stores from ".";

export type CompanyAppFilter = {
  status: CompanyAppStatus;
};

export default class CompanyAppStore {
  @observable apps: App[] = [];
  @observable.deep companyApps: CompanyApp[] = [];
  @observable.deep nonFilteredCompanyApps: CompanyApp[] = [];
  @observable appDepartments: AppDepartment[] = [];
  @observable selectedCompanyApp?: CompanyApp;
  @observable selectedCompanyApps: CompanyApp[] = [];
  @observable isConnectAppModalOpened: boolean = false;
  @observable isCompanyAppEditModalOpened: boolean = false;
  @observable isCompanyAppImportUsersModalOpened: boolean = false;
  @observable isCompanyAppAddUserModalOpened: boolean = false;
  @observable isAddCompanyAppsToUsersModalOpened: boolean = false;
  @observable isAddAppModalOpened: boolean = false;
  @observable isLoading: boolean = true;
  @observable appParamsModalRef: any;
  @observable fileUpdateLogs: Map<number, number> = new Map();

  @observable scrollPosition: number = 0;

  constructor(rootStore: RootStore) {
    when(
      () =>
        rootStore.companyStore.selectedUserCompany !== undefined &&
        !rootStore.companyStorageStore.isLoading,
      async () => {
        await this.getCompanyApps();
        await this.getAppDepartments();
        SocketHelper.addMessageHandler(this.handleMessage);
      }
    );
  }

  private handleMessage = async (data: any) => {
    if (!data.companyAppId) return;

    const companyApp = this.companyApps.find(
      (item) => item.id === data.companyAppId
    );

    if (data.processId === 2) {
      if (companyApp && data.isSyncComplete && data.isCreateIndexComplete) {
        companyApp.last_sync_at = Date.now();

        await stores.userActionLogStore.createUserActionLog(
          "Sync Completed",
          `Your ${companyApp.app.name} data is now up-to-date and ready for use.`,
          0,
          0,
          UserActionLogStatus.Success
        );
      } else if (companyApp) {
        companyApp.last_sync_at = 0;
        companyApp.status = CompanyAppStatus.Failed;

        await stores.userActionLogStore.createUserActionLog(
          "Sync Failed",
          `We encountered an error syncing data for ${companyApp.app.name}: ${data.syncDataMessage}.`,
          0,
          0,
          UserActionLogStatus.Declined
        );
      }
    } else if (data.processId === 3) {
      if (companyApp && data.status) {
        const uploadedCount: number = data.status.uploadedFilesLength || 0;
        const failedCount: number = data.status.notUploadedFilesLength || 0;

        let logId = this.fileUpdateLogs.get(data.companyAppId);

        if (!logId) {
          const createdLog =
            await stores.userActionLogStore.createUserActionLog(
              `${companyApp.app.name} File Update`,
              `${uploadedCount} new files updated, ${failedCount} files could not be updated.`,
              0,
              0,
              UserActionLogStatus.InProgress
            );
          logId = createdLog.id;
          this.fileUpdateLogs.set(data.companyAppId, logId);
        } else {
          await stores.userActionLogStore.updateUserActionLog({
            id: logId,
            user_id: stores.userStore.currentUser.id,
            action: `${companyApp?.app.name} File Update`,
            action_result: `${uploadedCount} new files updated, ${failedCount} files could not be updated.`,
            action_code: 0,
            action_result_code: 0,
            action_status: UserActionLogStatus.InProgress,
            modified_at: Date.now(),
          });
        }
      }
    } else if (data.processId === 4) {
      if (companyApp && data.message) {
        await stores.userActionLogStore.createUserActionLog(
          `${companyApp.app.name} Update Info`,
          `${data.message}`,
          0,
          0,
          UserActionLogStatus.Success
        );
      }
    } else if (data.processId === 5) {
      if (data.isDataDeletionComplete && data.isIndexDeletionComplete) {
        await stores.userActionLogStore.createUserActionLog(
          `${data.appName} Data Deletion Completed`,
          `All data for ${data.appName} has been successfully deleted.`,
          0,
          0,
          UserActionLogStatus.Success
        );
      }
    }
  };

  @action getCompanyApps = async () => {
    this.isLoading = true;
    this.apps = await getApps();

    this.nonFilteredCompanyApps = [];
    this.companyApps = [];

    if (stores.companyStore.selectedUserCompany !== undefined) {
      this.nonFilteredCompanyApps = await getCompanyApps(
        stores.companyStore.selectedUserCompany.id
      );
    }
    this.filterAndSortCompanyApps();
    this.isLoading = false;
  };

  @action findCompanyAppById = (
    companyAppId: number
  ): CompanyApp | undefined => {
    return this.companyApps.find((app) => app.id === companyAppId);
  };

  @action findAppById = (appId: number): App | undefined => {
    return this.apps.find((app) => app.id === appId);
  };

  @action filterAndSortCompanyApps = (
    searchTerm?: string,
    filter?: CompanyAppFilter
  ) => {
    this.selectedCompanyApp = undefined;
    this.selectedCompanyApps = [];

    this.companyApps = this.nonFilteredCompanyApps;

    if (searchTerm) {
      this.companyApps = this.nonFilteredCompanyApps.filter((companyApp) =>
        companyApp.company_user_apps.some((item) =>
          item.user.name?.includes(searchTerm)
        )
      );
    }

    if (filter) {
      this.companyApps = this.nonFilteredCompanyApps.filter(
        (companyApp) => companyApp.status === filter.status
      );
    }
  };

  @action createCompanyApp(
    app: App,
    credentials: string = "",
    sourceIds: string[] | undefined = undefined
  ): Promise<CompanyApp> {
    return new Promise(async (resolve, reject) => {
      if (!stores.companyStore.selectedUserCompany) {
        reject();
        return;
      }

      await stores.userActionLogStore.createUserActionLog(
        `${app.name} Connection`,
        `Connecting to your ${app.name} account...`,
        0,
        0,
        UserActionLogStatus.InProgress
      );

      const createdCompanyApp = await createCompanyApp(
        app.id,
        stores.companyStore.selectedUserCompany.id,
        credentials,
        CompanyAppStatus.InProgress,
        Date.now(),
        0,
        sourceIds
      );

      this.nonFilteredCompanyApps.push(createdCompanyApp);

      const checkNangoAppCredentialsResult = await checkNangoAppCredentials(
        createdCompanyApp.id
      );

      await stores.userActionLogStore.createUserActionLog(
        `${app.name} Connection`,
        checkNangoAppCredentialsResult.success
          ? `Your ${app.name} account connected successfully.`
          : `Failed to connect to ${app.name}: ${checkNangoAppCredentialsResult.message}`,
        0,
        0,
        checkNangoAppCredentialsResult.success
          ? UserActionLogStatus.Success
          : UserActionLogStatus.Declined
      );

      resolve(createdCompanyApp);
    });
  }

  @action
  async syncSelectedFiles(
    companyApp: CompanyApp,
    selectedDocs: any[]
  ): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!stores.companyStorageStore.selectedCompanyStorage) {
        await stores.userActionLogStore.createUserActionLog(
          "Sync Error",
          "No storage selected. Please select a storage to proceed.",
          0,
          0,
          UserActionLogStatus.Declined
        );
        return resolve(false);
      }

      const emotionalMessages = [
        "We are forging the path to greatness!",
        "Hold tight! Your files are syncing.",
        "The magic of synchronization is happening. ✨",
        "Stay calm, the gears are turning smoothly. 🛠️",
        "Your files are about to shine brighter than ever! 🌟",
        "Big things are coming, just a moment longer! 🚀",
      ];

      const getRandomMessage = () =>
        emotionalMessages[Math.floor(Math.random() * emotionalMessages.length)];

      const actionToastId = toast.info(` ${getRandomMessage()}`, {
        position: "top-center",
        autoClose: false,
        toastId: `file_sync_${companyApp.id}`,
        closeButton: true,
      });

      await stores.userActionLogStore.createUserActionLog(
        `${companyApp.app.name} Selected Files Sync`,
        "Starting synchronization of selected files...",
        0,
        0,
        UserActionLogStatus.InProgress
      );

      const intervalId = setInterval(() => {
        toast.update(actionToastId, {
          render: `${getRandomMessage()}`,
        });
      }, 3000);

      companyApp.last_sync_at = 0;
      companyApp.modified_at = 0;

      try {
        const result = await syncSelectedFiles(
          companyApp.company_id,
          companyApp.id,
          stores.companyStorageStore.selectedCompanyStorage.id,
          selectedDocs.map((doc) => doc.id)
        );

        clearInterval(intervalId);

        if (result.success) {
          if (!companyApp.source_ids) {
            companyApp.source_ids = [];
          }
          if (result.updatedFiles) {
            for (const f of result.updatedFiles) {
              const existing = companyApp.source_ids.find((x) => x.id === f.id);
              if (!existing) {
                companyApp.source_ids.push({
                  id: f.id,
                  name: f.name,
                  modifiedTime: f.modifiedTime,
                });
              } else {
                existing.modifiedTime = f.modifiedTime;
                existing.name = f.name;
              }
            }
          }

          await stores.userActionLogStore.createUserActionLog(
            `${companyApp.app.name} Selected Files Sync`,
            "The synchronization of your selected files has started.",

            0,
            0,
            UserActionLogStatus.Success
          );

          toast.update(actionToastId, {
            render: `Selected files sync started! 🎉`,
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          setTimeout(() => {
            toast.dismiss(actionToastId);
          }, 3000);

          resolve(true);
        } else {
          await stores.userActionLogStore.createUserActionLog(
            `${companyApp.app.name} Selected Files Sync`,
            `Error syncing files: ${result.message}`,
            0,
            0,
            UserActionLogStatus.Declined
          );

          toast.update(actionToastId, {
            render: `Selected files sync failed. 😢`,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          setTimeout(() => {
            toast.dismiss(actionToastId);
          }, 3000);

          resolve(false);
        }
      } catch (error) {
        clearInterval(intervalId);
        console.error("syncSelectedFiles error:", error);

        await stores.userActionLogStore.createUserActionLog(
          `${companyApp.app.name} Selected Files Sync`,
          `An unexpected error occurred while syncing selected files.`,
          0,
          0,
          UserActionLogStatus.Declined
        );

        toast.update(actionToastId, {
          render: `An unexpected error occurred during file sync. 😟`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setTimeout(() => {
          toast.dismiss(actionToastId);
        }, 3000);

        resolve(false);
      }
    });
  }

  @action
  syncCompanyAppData(companyApp: CompanyApp): Promise<boolean> {
    return new Promise(async (resolve, reject) => {
      if (!stores.companyStorageStore.selectedCompanyStorage) {
        await stores.userActionLogStore.createUserActionLog(
          "Sync Error",
          "No storage selected. Please select a storage to proceed.",
          0,
          0,
          UserActionLogStatus.Declined
        );
        return resolve(false);
      }

      const emotionalMessages = [
        "We are forging the path to greatness!",
        "Hold tight! Your app is leveling up.",
        "The magic of syncing is happening. ✨",
        "Stay calm, the gears are turning smoothly. 🛠️",
        "Your data is about to shine brighter than ever! 🌟",
        "Big things are coming, just a moment longer! 🚀",
      ];

      const getRandomMessage = () =>
        emotionalMessages[Math.floor(Math.random() * emotionalMessages.length)];

      const actionToastId = toast.info(`${getRandomMessage()}`, {
        position: "top-center",
        autoClose: false,
        toastId: `app_sync_${companyApp.id}`,
        closeButton: true,
      });

      await stores.userActionLogStore.createUserActionLog(
        `${companyApp.app.name} Data Sync`,
        "Starting data synchronization...",
        0,
        0,
        UserActionLogStatus.InProgress
      );

      const intervalId = setInterval(() => {
        toast.update(actionToastId, {
          render: `${getRandomMessage()}`,
        });
      }, 3000);

      companyApp.last_sync_at = 0;
      companyApp.modified_at = 0;

      try {
        const syncAppDataResult = await syncAppData(
          companyApp.company_id,
          companyApp.id,
          stores.companyStorageStore.selectedCompanyStorage.id
        );

        clearInterval(intervalId);

        if (syncAppDataResult.success) {
          await stores.userActionLogStore.createUserActionLog(
            `${companyApp.app.name} Data Sync`,
            "The synchronization of your data has started.",

            0,
            0,
            UserActionLogStatus.Success
          );

          toast.update(actionToastId, {
            render: `${companyApp.app.name} sync started! 🎉`,
            type: "success",
            isLoading: false,
            autoClose: 3000,
          });
          setTimeout(() => {
            toast.dismiss(actionToastId);
          }, 3000);

          resolve(true);
        } else {
          companyApp.status = CompanyAppStatus.Failed;

          await stores.userActionLogStore.createUserActionLog(
            `${companyApp.app.name} Data Sync`,
            `Error syncing data: ${syncAppDataResult.message}`,
            0,
            0,
            UserActionLogStatus.Declined
          );

          toast.update(actionToastId, {
            render: `${companyApp.app.name} sync failed. 😢`,
            type: "error",
            isLoading: false,
            autoClose: 3000,
          });
          setTimeout(() => {
            toast.dismiss(actionToastId);
          }, 3000);

          resolve(false);
        }
      } catch (error) {
        clearInterval(intervalId);
        console.error("syncCompanyAppData error:", error);

        companyApp.status = CompanyAppStatus.Failed;

        await stores.userActionLogStore.createUserActionLog(
          `${companyApp.app.name} Data Sync`,
          `An unexpected error occurred while syncing ${companyApp.app.name}.`,
          0,
          0,
          UserActionLogStatus.Declined
        );

        toast.update(actionToastId, {
          render: `${companyApp.app.name} unexpected error. 😟`,
          type: "error",
          isLoading: false,
          autoClose: 3000,
        });
        setTimeout(() => {
          toast.dismiss(actionToastId);
        }, 3000);

        resolve(false);
      }
    });
  }

  @action updateCompanyApp(companyApp: CompanyApp): Promise<boolean> {
    return new Promise(async (resolve) => {
      await updateCompanyApp(companyApp);

      const existingIndex = this.nonFilteredCompanyApps.findIndex(
        (item) => item.id === companyApp.id
      );
      if (existingIndex !== -1) {
        this.nonFilteredCompanyApps[existingIndex] = companyApp;
      }
      this.filterAndSortCompanyApps();
      resolve(true);
    });
  }

  @action deleteCompanyApp(companyApp: CompanyApp): Promise<boolean> {
    return new Promise(async (resolve) => {
      if (!stores.companyStorageStore.selectedCompanyStorage) {
        await stores.userActionLogStore.createUserActionLog(
          "Deletion Error",
          "No storage selected. Please select a storage to proceed.",
          0,
          0,
          UserActionLogStatus.Declined
        );
        return resolve(false);
      }

      await stores.userActionLogStore.createUserActionLog(
        `${companyApp.app.name} Deletion`,
        "Deleting your connection...",
        0,
        0,
        UserActionLogStatus.InProgress
      );

      await deleteAppData(
        companyApp.company_id,
        companyApp.id,
        stores.companyStorageStore.selectedCompanyStorage.id
      );

      await stores.userActionLogStore.createUserActionLog(
        `${companyApp.app.name} Deleted`,
        "Your connection has been deleted successfully.",
        0,
        0,
        UserActionLogStatus.Success
      );

      this.nonFilteredCompanyApps = this.nonFilteredCompanyApps.filter(
        (item) => item.id !== companyApp.id
      );
      this.filterAndSortCompanyApps();

      resolve(true);
    });
  }

  @action deleteSelectedCompanyApps(): Promise<boolean> {
    return new Promise(async (resolve) => {
      for (const companyApp of this.selectedCompanyApps) {
        await stores.userActionLogStore.createUserActionLog(
          "Bulk Deletion",
          `Starting deletion of ${companyApp.app.name}...`,
          0,
          0,
          UserActionLogStatus.InProgress
        );

        await deleteCompanyApp(companyApp.id);

        this.nonFilteredCompanyApps = this.nonFilteredCompanyApps.filter(
          (item) => item.id !== companyApp.id
        );
        this.filterAndSortCompanyApps();

        await stores.userActionLogStore.createUserActionLog(
          "Bulk Deletion",
          `${companyApp.app.name} has been deleted successfully.`,
          0,
          0,
          UserActionLogStatus.Success
        );
      }
      this.selectedCompanyApps = [];
      resolve(true);
    });
  }

  @action addCompanyUsersToApps(
    selectedCompanyApps: CompanyApp[],
    selectedUsers: CompanyUser[]
  ): Promise<boolean> {
    return new Promise(async (resolve) => {
      for (const companyApp of selectedCompanyApps) {
        for (const companyUser of selectedUsers) {
          const exists = companyApp.company_user_apps.some(
            (item) =>
              item.app_id.toString() === companyApp.app_id.toString() &&
              item.user_id === companyUser.user_id
          );
          if (exists) {
            await stores.userActionLogStore.createUserActionLog(
              "Invite Error",
              i18n.ToastMessages.userAlreadyInvitedError,
              0,
              0,
              UserActionLogStatus.Declined
            );
            return resolve(false);
          }

          if (companyUser.user.email) {
            const appIndex = this.nonFilteredCompanyApps.findIndex(
              (item) => item.id === companyApp.id
            );
            if (appIndex !== -1) {
              const companyUserApp = await createCompanyUserApp(
                companyUser.user_id,
                companyApp.app_id,
                companyApp.id,
                "",
                CompanyUserAppStatus.InProgress,
                Date.now()
              );
              this.nonFilteredCompanyApps[appIndex].company_user_apps.push(
                companyUserApp
              );
            }
          }
        }
      }
      this.filterAndSortCompanyApps();
      resolve(true);
    });
  }

  @action deleteCompanyUserApps(
    companyUserApps: CompanyUserApp[]
  ): Promise<boolean> {
    return new Promise(async (resolve) => {
      for (const app of companyUserApps) {
        await deleteCompanyUserApp(app.id);
        this.getCompanyApps();
      }
      resolve(true);
    });
  }

  @action getAppDepartments = async () => {
    this.appDepartments = await getAppDepartments();
  };

  @action getDepartmentApp = async (selectedDepartmentId: number) => {
    try {
      const data = await getAppDepartments();
      const filteredData = data.filter(
        (item) => item.department_id === selectedDepartmentId
      );
      this.appDepartments = filteredData;
      return filteredData;
    } catch (error) {
      console.error("Failed to fetch app departments", error);
      return [];
    }
  };
}
