/* eslint-disable react/jsx-no-target-blank */
import { Component } from "react";
import { observer } from "mobx-react";

import { ConnectionParam } from "../../models/ConnectionParam";
import { ConnectionCredential } from "../../models/ConnectionCredential";
import { App } from "../../models/App";

interface Props {
  app: App;
  handleParamChange: (
    credential: ConnectionCredential | undefined,
    activationMessage: string,
    hasError: boolean
  ) => void;
  params: ConnectionParam[];
  credential: ConnectionCredential | undefined;
  activationMessage: string;
  hasError: boolean;
}

interface State {
  credential: ConnectionCredential | undefined;
  activationMessage: string;
  hasError: boolean;
  selectedOptionType: string | undefined;
}

@observer
export default class AppParamView extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      credential: this.props.credential,
      activationMessage: this.props.activationMessage,
      hasError: this.props.hasError,
      selectedOptionType: undefined,
    };
  }

  componentWillMount() {
    if (this.props.params && this.props.params[0].options) {
      this.handleInputChange(
        this.props.params[0].options[0],
        this.props.params[0].key
      );
    }
  }

  componentWillReceiveProps(nextProps: any) {
    this.setState({
      credential: nextProps.credential,
      activationMessage: nextProps.activationMessage,
      hasError: nextProps.hasError,
    });
  }

  private handleInputChange = (value: string, key: string) => {
    const updatedCredential: ConnectionCredential = this.state.credential
      ? this.state.credential
      : {
          connectionId: "",
          params: [],
        };

    const paramIndex = updatedCredential.params.findIndex(
      (param) => param.key === key
    );

    if (paramIndex !== -1) {
      updatedCredential.params[paramIndex].info = value;
    } else {
      updatedCredential.params.push({ key: key, info: value });
    }

    this.props.handleParamChange(updatedCredential, "", false);
  };

  render() {
    const { params, app } = this.props;
    const { credential, activationMessage, hasError, selectedOptionType } =
      this.state;

    return (
      <>
        {params &&
          params.map((param) => {
            if (param.key === "verification" && app.id.toString() === "67") {
              return (
                <div key={param.key} className="fv-row mt-8">
                  <div className="fw-bolder text-dark fs-4 mb-1">
                    {`Install CompanyDNA to your ${app.name}`}
                  </div>

                  <span className="text-gray-700 mt-1 fw-semibold fs-6">
                    Before connecting, this application requires you to install
                    the CompanyDNA app from the app store. Once you have
                    installed our app, you can proceed to test your connection.
                  </span>

                  <br />
                  <br />

                  <a href={param.info} target="_blank" rel="noreferrer">
                    <img
                      alt="Add to monday.com"
                      height="42"
                      src="https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/remote_mondaycom_static/uploads/Tal/4b5d9548-0598-436e-a5b6-9bc5f29ee1d9_Group12441.png"
                    />
                  </a>
                </div>
              );
            } else if (
              param.key === "verification" &&
              app.id.toString() === "41"
            ) {
              return (
                <div key={param.key} className="fv-row mt-8">
                  <div className="fw-bolder text-dark fs-4 mb-1">
                    {`Install CompanyDNA to your ${app.name}`}
                  </div>

                  <span className="text-gray-700 mt-1 fw-semibold fs-6">
                    Before connecting, this application requires you to add the
                    CompanyDNA app to your channels. Once you have added the
                    app, you can proceed to test your connection.
                  </span>

                  <br />
                  <br />

                  <a href={param.info} target="_blank" rel="noreferrer">
                    <img
                      alt="Add to Slack"
                      height="42"
                      src="https://platform.slack-edge.com/img/add_to_slack.png"
                    />
                  </a>
                </div>
              );
            } else if (
              param.key === "verification" &&
              app.id.toString() === "144"
            ) {
              return (
                <div key={param.key} className="fv-row mt-8">
                  <div className="fw-bolder text-dark fs-4 mb-1">
                    {`Install CompanyDNA to your ${app.name}`}
                  </div>

                  <span className="text-gray-700 mt-1 fw-semibold fs-6">
                    Before connecting, this application requires you to enable
                    CompanyDNA app to your account. Once you have enabled the
                    app, you can proceed to test your connection.
                  </span>

                  <br />
                  <br />

                  <a
                    href={param.info}
                    target="_blank"
                    className="btn btn-flex flex-center btn-primary w-40px w-md-auto h-40px px-0 px-md-6 text-nowrap"
                  >
                    Enable
                  </a>
                </div>
              );
            }

            return (
              <div key={param.key} className="fv-row mt-8">
                {param && param.options ? (
                  <select
                    className="form-select form-select-solid fw-bolder"
                    data-kt-select2="true"
                    data-placeholder="Select option"
                    onChange={(e) => {
                      this.handleInputChange(e.target.value, param.key);
                      this.setState({ selectedOptionType: e.target.value });
                    }}
                    value={selectedOptionType}
                    onClick={(e) => e.stopPropagation()}
                  >
                    {param.options.map((option, index) => (
                      <option key={index} value={option}>
                        {option}
                      </option>
                    ))}
                  </select>
                ) : (
                  <input
                    type="text"
                    className="form-control form-control-lg form-control-sm"
                    name={param.key}
                    placeholder={param.info}
                    value={
                      credential?.params.find((item) => item.key === param.key)
                        ?.info
                    }
                    disabled={activationMessage && !hasError ? true : false}
                    onChange={(e) =>
                      this.handleInputChange(e.target.value, param.key)
                    }
                  />
                )}
              </div>
            );
          })}
      </>
    );
  }
}
