import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Edge, Node } from "@xyflow/react";

import stores from "../../stores";
import { DashboardSection } from "../../models/DashboardSection";

import { SectionFlowLoading } from "./loading/SectionFlowLoading";

interface SectionFlowDetailStepProps {
  onBack: () => void;
  onSave: (newSection: DashboardSection) => void;
  isEditMode: boolean;
  nodes: Node[];
  edges: Edge[];
  selectedSection: DashboardSection | undefined;
}

const SectionFlowDetailStep: React.FC<SectionFlowDetailStepProps> = ({
  onBack,
  onSave,
  isEditMode,
  nodes,
  edges,
  selectedSection,
}) => {
  const [name, setName] = useState<string>("");
  const [description, setDescription] = useState<string>("");
  const [error, setError] = useState<string>("");
  const [loading, setLoading] = useState<boolean>(false);

  useEffect(() => {
    if (selectedSection) {
      setName(selectedSection.section_name);
      setDescription(selectedSection.section_description);
    } else {
      setName("");
      setDescription("");
    }
  }, [selectedSection]);

  const handleSave = async () => {
    if (!name.trim()) {
      setError("Name is required.");
      return;
    }

    setLoading(true);

    onSave({
      id: 0,
      company_id: stores.companyAppStore.companyApps[0].company_id,
      section_name: name,
      section_description: description,
      is_manager_section: true,
      order: 1,
      section_preferences: "",
      view: null,
      suggestion_view: null,
      nodes: JSON.stringify(nodes).replace(/\\"/g, '"'),
      edges: JSON.stringify(edges).replace(/\\"/g, '"'),
      node_objects_list: nodes,
      edge_objects_list: edges,
    });
  };

  return (
    <div className="d-flex justify-content-center min-vh-100">
      <form
        className="form w-50"
        onSubmit={(e) => {
          e.preventDefault();
          handleSave();
        }}
      >
        {loading && <SectionFlowLoading />}
        {!loading && (
          <div>
            <div className="w-100 mt-15 mb-7 ">
              <h4 className="fw-bold fs-2">Section Flow Info</h4>
            </div>

            <div className="mb-10">
              <label className="fw-bolder fs-6 ms-1 mb-3">Flow Name:</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                value={name}
                onChange={(e) => setName(e.target.value)}
                placeholder="Enter Flow Name"
                required
                disabled={loading}
              />
            </div>

            <div className="mb-10">
              <label className="fw-bolder fs-6 ms-1 mb-3">Description:</label>
              <input
                type="text"
                className="form-control form-control-lg form-control-solid"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                placeholder="Enter Description (Optional)"
                disabled={loading}
              />
            </div>

            {error && (
              <div className="alert alert-danger" role="alert">
                {error}
              </div>
            )}
          </div>
        )}
        <div
          className="fixed-bottom pb-20 pt-10"
          style={{
            height: "80px",
            backgroundColor:
              document.documentElement.getAttribute("data-bs-theme") === "dark"
                ? "#151521"
                : "#fff",
          }}
        >
          <div className="container d-flex justify-content-between w-50">
            <button
              type="button"
              className="btn btn-secondary"
              disabled={loading}
              onClick={onBack}
            >
              <i className="ki-duotone ki-arrow-left fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i>
              Back
            </button>
            <button
              type="submit"
              className="btn btn-primary"
              disabled={!name.trim() || loading}
            >
              {loading
                ? isEditMode
                  ? "Updating Flow..."
                  : "Saving Flow..."
                : isEditMode
                ? "Update"
                : "Save"}
              {/* <i className="ki-duotone ki-arrow-right fs-2">
                <span className="path1"></span>
                <span className="path2"></span>
              </i> */}
            </button>
          </div>
        </div>
      </form>
    </div>
  );
};

export default observer(SectionFlowDetailStep);
