import React, { FC, useState, useEffect } from "react";
import { KTIcon } from "../../../helpers";
import stores from "../../stores";
import { createCheckoutSession } from "../../../helpers/api";

const stripeKey = process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY || "";
if (!stripeKey) {
  throw new Error("Stripe key is not defined");
}

const isTestMode = stripeKey.includes("test");

const plans = [
  {
    title: "Premium",
    subTitle: "Advanced Features for Growing Teams",
    description:
      "Enjoy unlimited applications, employees, LLMs, and storage connections. Includes premium tools like Auto Sync and VoiceAsk. Perfect for teams requiring flexibility and more advanced functionality.",
    priceMonth: "49$",
    priceAnnual: "490$",
    isPopular: true,
    priceIdMonth: process.env.REACT_APP_MONTHLY_PRICE_ID,
    priceIdAnnual: process.env.REACT_APP_YEARLY_PRICE_ID,
    paymentLinks: {
      month: "#",
      annual: "#",
    },
    default: false,
    custom: false,
    tokenInfo: "Unlimited token",
    features: [
      { title: "Unlimited access to applications", supported: true },
      { title: "Unlimited employee additions", supported: true },
      { title: "Unlimited LLM connections", supported: true },
      { title: "Unlimited storage connection", supported: true },
      { title: "1 Admin", supported: true },
      { title: "VoiceAsk", supported: true },
      { title: "1 Avatar", supported: true },
      { title: "Auto Sync", supported: true },
      { title: "Custom LLM tuning", supported: false },
      { title: "The Chief", supported: false },
      { title: "Use API", supported: false },
    ],
  },
  {
    title: "Enterprise",
    subTitle: "Comprehensive Solutions for Large Enterprises",
    description:
      "Full access to advanced features like unlimited admins, storage, and employees. Get specialized services like custom LLMs. Perfect for large organizations with specific needs.",
    default: false,
    custom: true,
    priceId: isTestMode
      ? "price_1PqZumBEosKxgEvXihK0LN6z"
      : "price_1PqweqwosKxgEmvXihK0LN6z",
    tokenInfo: "Unlimited token",
    features: [
      { title: "Unlimited access to applications", supported: true },
      { title: "Unlimited employee additions", supported: true },
      { title: "Unlimited LLM connections", supported: true },
      { title: "Unlimited storage connections", supported: true },
      { title: "Unlimited admin additions", supported: true },
      { title: "VoiceAsk", supported: true },
      { title: "Avatars", supported: true },
      { title: "Auto Sync", supported: true },
      { title: "Custom LLM tuning", supported: true },
      { title: "The Chief", supported: true },
      { title: "Use API", supported: true },
    ],
  },
];

const UpgradePlanPage: FC = () => {
  const [selectedPlan, setSelectedPlan] = useState("Premium");
  const [billingType, setBillingType] = useState<"monthly" | "annual">(
    "monthly"
  );
  const [isCurrentPlan, setIsCurrentPlan] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const [totalUsersCount, setTotalUsersCount] = useState(0);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const initialize = async () => {
      try {
        await checkSubscription();
        await fetchCompanyUsers();
      } catch (err) {
        console.error("Initialization error:", err);
      } finally {
        setIsLoading(false);
      }
    };

    initialize();
  });

  const checkSubscription = async () => {
    const currentUser = stores.userStore.currentUser;
    if (!currentUser || !currentUser.email) {
      console.error("User or email is undefined, cannot proceed.");
      return;
    }

    if (currentUser.subscription) {
      const currentPriceId = currentUser.subscription.items.data[0].price.id;
      const premiumPlan = plans.find((plan) => plan.title === "Premium");

      if (
        (billingType === "monthly" &&
          currentPriceId === premiumPlan?.priceIdMonth) ||
        (billingType === "annual" &&
          currentPriceId === premiumPlan?.priceIdAnnual)
      ) {
        setIsCurrentPlan(true);
        window.location.href = "/chat";
      } else {
        setIsCurrentPlan(false);
      }
    } else {
      await retryCheckSubscription();
    }
  };

  const retryCheckSubscription = async (retries = 5, delay = 1000) => {
    for (let attempt = 0; attempt < retries; attempt++) {
      try {
        if (stores.userStore.currentUser?.email) {
          await stores.userStore.getStripeUser(
            stores.userStore.currentUser.email
          );
          const updatedSubscription =
            stores.userStore.currentUser?.subscription;

          if (updatedSubscription?.status === "active") {
            setIsCurrentPlan(true);
            window.location.href = "/chat";
            break;
          }
        } else {
          console.error("Email is undefined.");
          break;
        }
      } catch (error) {
        console.error("Subscription check failed", error);
      }
      await new Promise((resolve) => setTimeout(resolve, delay));
    }
  };

  const fetchCompanyUsers = async () => {
    try {
      const companyUsers = await stores.companyUserStore.getCompanyUsers();
      setTotalUsersCount(companyUsers.length);
    } catch (err) {
      console.error("Error fetching company users:", err);
    }
  };

  const handlePlanSelect = (planTitle: string) => {
    setSelectedPlan(planTitle);
  };

  const handleBillingTypeChange = (type: "monthly" | "annual") => {
    setBillingType(type);
  };

  const handleCheckoutSession = async () => {
    try {
      const priceId =
        billingType === "monthly"
          ? plans.find((plan) => plan.title === "Premium")?.priceIdMonth
          : plans.find((plan) => plan.title === "Premium")?.priceIdAnnual;

      if (!priceId) {
        throw new Error("Price ID not found for the selected plan");
      }

      const currentSubscriptionId = stores.userStore.currentUser.subscription
        ? stores.userStore.currentUser.subscription.id
        : null;

      const customerEmail = stores.userStore.currentUser.email;
      if (!customerEmail) {
        throw new Error("User email is undefined.");
      }

      const stripeProfile = stores.userStore.currentUser.stripeProfile;
      if (!stripeProfile || !stripeProfile.id) {
        throw new Error("Stripe profile ID is undefined.");
      }

      const stripeSession = await createCheckoutSession(
        priceId,
        `${process.env.REACT_APP_PUBLIC_URL}/success`,
        `${process.env.REACT_APP_PUBLIC_URL}/cancel`,
        currentSubscriptionId,
        stripeProfile.id,
        1
      );

      sessionStorage.setItem("stripeSessionId", stripeSession.id);
      window.location.href = stripeSession.url;
    } catch (error) {
      console.error("Error during Stripe checkout:", error);
      setError("Error during Stripe checkout. Please try again.");
    }
  };

  const handleUpgradePlan = async () => {
    if (selectedPlan === "Enterprise") {
      window.location.href = "https://www.companydna.ai/waitlist";
    } else if (selectedPlan === "Premium" && !isCurrentPlan) {
      await handleCheckoutSession();
    }
  };

  const LoadingScreen = () => (
    <div className="d-flex justify-content-center align-items-center vh-100">
      <div className="spinner-border text-primary" role="status">
        <span className="visually-hidden">Loading...</span>
      </div>
    </div>
  );

  if (isLoading) {
    return <LoadingScreen />;
  }

  return (
    <div className="container w-75 mt-5 mb-5 pb-10 pt-5">
      <div className="card shadow-sm">
        <div className="card-body rounded">
          <div className="text-center mb-5">
            <h1>Upgrade Your Plan</h1>
            <p className="text-muted">
              Select a plan to continue using our services with advanced
              features.
            </p>
          </div>

          <div className="text-center mb-5">
            <p>
              <strong>
                Empowering a team of {totalUsersCount} users in your company
              </strong>
            </p>
          </div>

          <div className="d-flex justify-content-center mb-10">
            <div className="nav nav-tabs border-0">
              <button
                type="button"
                className={`nav-link btn btn-outline-secondary ${
                  billingType === "monthly" ? "active" : ""
                }`}
                onClick={() => handleBillingTypeChange("monthly")}
                disabled={selectedPlan === "Enterprise"}
              >
                Monthly Billing
              </button>
              <button
                type="button"
                className={`nav-link btn btn-outline-secondary ${
                  billingType === "annual" ? "active" : ""
                }`}
                onClick={() => handleBillingTypeChange("annual")}
                disabled={selectedPlan === "Enterprise"}
              >
                Annual Billing
              </button>
            </div>
          </div>

          {/* Plan Cards */}
          <div className="row g-10 align-items-stretch">
            {plans.map((plan, index) => (
              <div className="col-lg-6" key={index}>
                <div
                  className={`card card-bordered card-hover h-100 ${
                    selectedPlan === plan.title ? "bg-light-primary" : ""
                  }`}
                  onClick={() => handlePlanSelect(plan.title)}
                  style={{ cursor: "pointer", position: "relative" }}
                >
                  <div className="card-body text-center p-10">
                    <h3 className="card-title mb-2">{plan.title}</h3>
                    {plan.isPopular && (
                      <span
                        style={{
                          position: "absolute",
                          top: "-10px",
                          right: "-10px",
                          backgroundColor: "#ffc107",
                          color: "#000",
                          padding: "5px 10px",
                          borderRadius: "5px",
                          fontSize: "12px",
                          fontWeight: "bold",
                        }}
                      >
                        Most Popular
                      </span>
                    )}
                    <p className="card-text text-muted mb-4">{plan.subTitle}</p>
                    <h4 className="fw-bold text-primary mb-4 fs-2">
                      {plan.title === "Enterprise"
                        ? "Contact Us"
                        : billingType === "monthly"
                        ? `${plan.priceMonth}/month`
                        : `${plan.priceAnnual}/year`}
                      {plan.title === "Premium" && (
                        <span className="fs-6 text-muted"> per user</span>
                      )}
                    </h4>
                    <p className="text-muted">{plan.description}</p>
                    <div className="w-100 mb-10">
                      {plan.features.map((feature, idx) => (
                        <div
                          className="d-flex align-items-center mb-7"
                          key={idx}
                        >
                          <span
                            className={`fw-bold fs-5 text-${
                              feature.supported ? "gray-700" : "gray-400"
                            } flex-grow-1`}
                          >
                            {feature.title}
                          </span>
                          <KTIcon
                            iconName={
                              feature.supported
                                ? "check-circle"
                                : "cross-circle"
                            }
                            className={`fs-1 ${
                              feature.supported ? "text-success" : ""
                            }`}
                          />
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            ))}
          </div>

          {/* Action Buttons */}
          <div className="d-flex flex-column flex-center flex-row-fluid mt-10">
            <div className="d-flex flex-center">
              <button
                type="button"
                className="btn btn-primary"
                onClick={handleUpgradePlan}
                disabled={selectedPlan === "Enterprise" ? false : isCurrentPlan}
              >
                {selectedPlan === "Enterprise"
                  ? "Contact Us"
                  : isCurrentPlan
                  ? "Current Plan"
                  : "Upgrade to Premium Plan"}
              </button>
            </div>
            {error && <div className="alert alert-danger mt-4">{error}</div>}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UpgradePlanPage;
