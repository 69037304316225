/* @flow */

import { CompanyStorage } from "../../app/models/CompanyStorage";
import { DefaultResult } from "../../app/models/DefaultResult";
import stores from "../../app/stores";

export default async function updateCompanyStorage(
  isCustom: boolean,
  companyStorage: CompanyStorage
): Promise<DefaultResult> {
  return new Promise(async (resolve, reject) => {
    fetch(`${process.env.REACT_APP_API_URL}/updateStorage`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify({
        isCustom: isCustom,
        companyId: Number(stores.companyStore.selectedUserCompany?.id),
        updatedFields: {
          id: Number(companyStorage.id),
          credentials: companyStorage.credentials,
        },
      }),
      credentials:
        process.env.REACT_APP_IS_DEV === "false" ? "include" : "same-origin",
    })
      .then((res) => res.json())
      .then((data) => {
        if (data) {
          const result: DefaultResult = data;

          if (result.success) {
            resolve(result);
          } else {
            reject();
          }
        } else {
          reject();
        }
      });
  });
}
