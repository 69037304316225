/* eslint-disable react-hooks/exhaustive-deps */
import React, { useEffect, useMemo } from "react";
import { useTable, ColumnInstance, Row } from "react-table";
import { observer } from "mobx-react";

import i18n from "../../../../../i18n";
import { KTCardBody } from "../../../../../helpers";
import { CustomUser } from "../../../../models/CustomUser";
import stores from "../../../../stores";

import { CompanyUserListLoading } from "../components/loading/CompanyUserListLoading";
import { CompanyUsersListPagination } from "../components/pagination/CompanyUsersListPagination";
import { companyUsersColumns } from "./columns/_columns";
import { CustomHeaderColumn } from "./columns/CustomHeaderColumn";
import { CustomRow } from "./columns/CustomRow";

export const CompanyUsersTable: React.FC = observer(() => {
  const refreshData = async () => {
    await stores.companyUserStore.getCompanyUsers();
    await stores.companyUserStore.getCompanyUserInvites();
    await stores.companyUserStore.getCustomUsers();
  };

  useEffect(() => {
    refreshData();
  }, [stores.companyUserStore.selectedCompanyUserInvite]);

  useEffect(() => {
    async function loadAdminSubs() {
      if (
        stores.userStore.isCurrentUserAdmin &&
        stores.userStore.currentUser.email
      ) {
        await stores.userStore.fetchAdminSubscriptions(
          stores.userStore.currentUser.email
        );
      }
    }
    loadAdminSubs();
  }, [stores.userStore.isCurrentUserAdmin, stores.userStore.currentUser.email]);

  const data = stores.companyUserStore.filteredCustomUsers;
  const columns = useMemo(() => companyUsersColumns, []);

  const { getTableProps, getTableBodyProps, headers, rows, prepareRow } =
    useTable({
      columns,
      data,
    });

  if (stores.companyUserStore.isLoading) {
    return (
      <div className="card mb-5 mb-xl-10">
        <div
          className="d-flex justify-content-center align-items-center"
          style={{ height: "70vh" }}
        >
          <CompanyUserListLoading />
        </div>
      </div>
    );
  }

  return (
    <KTCardBody className="py-4">
      <div className="d-flex flex-column flex-lg-row">
        <div className="flex-lg-row-fluid">
          <div className="card ms-8 me-12">
            <div className="align-items-center border-0 mt-5 mb-8">
              <span className="fw-bolder text-dark fs-2">Company Users</span>
            </div>
            <div className="table-responsive">
              <table
                id="kt_table_users"
                className="table align-middle table-row-dashed fs-6 gy-5 dataTable no-footer"
                {...getTableProps()}
              >
                <thead>
                  <tr className="text-start text-muted fw-bolder fs-7 text-uppercase gs-0">
                    {headers.map((column: ColumnInstance<CustomUser>) => (
                      <CustomHeaderColumn key={column.id} column={column} />
                    ))}
                  </tr>
                </thead>

                <tbody
                  className="text-gray-600 fw-bold"
                  {...getTableBodyProps()}
                >
                  {rows.length > 0 ? (
                    rows.map((row: Row<CustomUser>, i) => {
                      prepareRow(row);
                      return <CustomRow row={row} key={`row-${i}-${row.id}`} />;
                    })
                  ) : (
                    <tr>
                      <td colSpan={8}>
                        <div className="text-gray-600 d-flex text-center w-100 align-content-center justify-content-center mt-6 mb-4">
                          {i18n.CompanyUsersTable.noMatchingRecordsFound}
                        </div>
                      </td>
                    </tr>
                  )}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <CompanyUsersListPagination />
    </KTCardBody>
  );
});
