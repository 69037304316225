/* @flow */

import { gql } from "@apollo/client";
import { get } from "lodash";

import ApolloClientHelper from "../ApolloClientHelper";
import { Company } from "../../app/models/Company";

export default async function updateCompany(
  company: Company
): Promise<boolean> {
  const client = ApolloClientHelper.getApolloClient();

  const mutation = gql`
    mutation {
      updateCompany(
        id: ${company.id}
        department_id: ${company.department_id}
        name: "${company.name}"
        logo: "${company.logo}"
        teamSize: "${company.teamSize}"
        industry: "${company.industry}"
        plan: "${company.plan}"
        index_status: "${company.index_status}"
        index_updated_at: ${company.index_updated_at}
        available_avatar_count: ${Number(company.available_avatar_count)}
        is_chief_available: ${Number(company.is_chief_available)}
        is_api_available: ${Number(company.is_api_available)}
        is_fine_tuned_model_active: ${Number(
          company.is_fine_tuned_model_active
        )}
        fine_tuning_progress: ${Number(company.fine_tuning_progress)}
        timezone: "${company.timezone}"
        website: "${company.website}"
      ) 
    }
  `;

  const updateCompanyMutationResult = await client.mutate({ mutation });

  const result = get(updateCompanyMutationResult, "data.updateCompany", null);

  return result !== null && result[0] > 0;
}
