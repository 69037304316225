import { FC, lazy, Suspense } from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { observer } from "mobx-react-lite";
import TopBarProgress from "react-topbar-progress-indicator";

import { MasterLayout } from "../../layout/MasterLayout";
import { getCSSVariableValue } from "../../assets/ts/_utils";
import { WithChildren } from "../../helpers";
import { CompanyUsersWrapper } from "../pages/CompanyUsers/CompanyUsersWrapper";
import { ConnectionsWrapper } from "../pages/CompanyConnections/ConnectionsWrapper";
import { CompanyModelsWrapper } from "../pages/CompanyModels/CompanyModelsWrapper";
import { CompanyStoragesWrapper } from "../pages/CompanyStorages/CompanyStoragesWrapper";
import { AvatarWrapper } from "../pages/CompanyAvatars/AvatarsWrapper";
import { ChatWrapper } from "../pages/ChatPage/ChatWrapper";
import AiManagerWrapper from "../pages/AiManager/AiManagerWrapper";
import { TutorialsWrapper } from "../pages/TutorialsPage/TutorialsWrapper";
import { AccountWrapper } from "../pages/accounts/AccountWrapper";
import { TheChiefDashboardWrapper } from "../pages/theChiefDashboard/TheChiefDashboardWrapper";
import stores from "../stores";

const ProfilePage = lazy(() => import("../modules/profile/ProfilePage"));
const WidgetsPage = lazy(() => import("../modules/widgets/WidgetsPage"));
const SubscriptionManagement = lazy(
  () =>
    import(
      "../../../src/app/pages/SubscriptionManagementPage/SubscriptionManagement"
    )
);

const PrivateRoutes: FC = observer(() => {
  const isElectron = window.electronAPIs?.isElectron ?? false;

  const canAccessAiManager =
    stores.companyAvatarStore.companyAvatars.length >= 1 ||
    stores.companyStore.selectedUserCompany?.is_chief_available;

  const canAccessAccount =
    stores.userStore.isCurrentUserAdmin ||
    stores.companyStore.selectedUserCompany?.plan === "Premium";

  return (
    <Routes>
      <Route element={<MasterLayout />}>
        <Route path="chat" element={<ChatWrapper />} />

        {!isElectron ? (
          <>
            <Route path="auth/*" element={<Navigate to="/dashboard" />} />

            {stores.userStore.isCurrentUserAdmin && (
              <>
                <Route path="company-users" element={<CompanyUsersWrapper />} />
                <Route path="connections" element={<ConnectionsWrapper />} />
                <Route path="models" element={<CompanyModelsWrapper />} />
                <Route path="storages" element={<CompanyStoragesWrapper />} />
                <Route path="avatars" element={<AvatarWrapper />} />
                <Route path="tutorials" element={<TutorialsWrapper />} />
                <Route
                  path="the-chief-workspace"
                  element={<TheChiefDashboardWrapper />}
                />
              </>
            )}

            {canAccessAccount && (
              <Route path="account" element={<AccountWrapper />} />
            )}

            <Route
              path="subscription-management"
              element={
                stores.userStore.isFreeUser ? (
                  <Navigate to="/dashboard" />
                ) : (
                  <Suspense fallback={<div>Loading...</div>}>
                    <SubscriptionManagement
                      customerId={
                        stores.userStore.currentUser.stripeProfile?.id || ""
                      }
                    />
                  </Suspense>
                )
              }
            />

            {stores.userStore.isCurrentUserAdmin && canAccessAiManager && (
              <Route path="ai-manager" element={<AiManagerWrapper />} />
            )}

            {stores.userStore.isCurrentUserAdmin && (
              <>
                <Route
                  path="crafted/pages/profile/*"
                  element={
                    <SuspensedView>
                      <ProfilePage />
                    </SuspensedView>
                  }
                />
                <Route
                  path="crafted/pages/widgets/*"
                  element={
                    <SuspensedView>
                      <WidgetsPage />
                    </SuspensedView>
                  }
                />
              </>
            )}
          </>
        ) : (
          <Route path="*" element={<Navigate to="/chat" />} />
        )}

        <Route path="*" element={<Navigate to="/error/404" />} />
      </Route>
    </Routes>
  );
});

const SuspensedView: FC<WithChildren> = ({ children }) => {
  const baseColor = getCSSVariableValue("--bs-primary");
  TopBarProgress.config({
    barColors: { "0": baseColor },
    barThickness: 1,
    shadowBlur: 5,
  });
  return <Suspense fallback={<TopBarProgress />}>{children}</Suspense>;
};

export { PrivateRoutes };
