import React, { FC } from "react";

const NonAdminInfoPage: FC = () => {
  return (
    <div className="d-flex flex-column flex-center min-vh-100 py-10">
      <div className="w-lg-50 w-md-75 w-sm-100">
        <div className="card card-custom shadow-sm">
          <div className="card-body text-center p-10">
            <h2 className="fw-bold mb-5">
              Please Contact Your Company Administrator
            </h2>
            <p className="text-muted fs-6">
              It appears that your account does not have an active subscription.
              Once your company administrator assigns a subscription for you,
              you will be able to continue using our system.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NonAdminInfoPage;
