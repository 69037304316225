import React, { useState, useEffect } from "react";
import * as Yup from "yup";
import { useFormik } from "formik";
import clsx from "clsx";
import { observer } from "mobx-react";
import ReactGA from "react-ga4";

import i18n from "../../../../../i18n";
import { CompanyUserInvite } from "../../../../models/CompanyUserInvite";
import stores from "../../../../stores";
import { CompanyUserListLoading } from "../components/loading/CompanyUserListLoading";

import createCheckoutSession from "../../../../../helpers/api/createCheckoutSession";

type Props = {
  companyUserInvite?: CompanyUserInvite;
};

const editUserSchema = Yup.object().shape({
  email: Yup.string()
    .email("Wrong email format")
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Email is required"),
  name: Yup.string()
    .min(3, "Minimum 3 symbols")
    .max(50, "Maximum 50 symbols")
    .required("Name is required"),
});

export const CompanyUserEditModalForm: React.FC<Props> = observer(
  ({ companyUserInvite }) => {
    const [subscriptionPlan, setSubscriptionPlan] = useState<
      "monthly" | "annual" | null
    >(null);
    const [pricePerUser, setPricePerUser] = useState(0);

    useEffect(() => {
      const subscription = stores.userStore.currentUser.subscription;
      if (subscription) {
        const currentPriceId = subscription.items.data[0].price.id;
        const monthlyPriceId = process.env.REACT_APP_MONTHLY_PRICE_ID;
        const annualPriceId = process.env.REACT_APP_YEARLY_PRICE_ID;
        if (currentPriceId === monthlyPriceId) {
          setSubscriptionPlan("monthly");
          setPricePerUser(49);
        } else if (currentPriceId === annualPriceId) {
          setSubscriptionPlan("annual");
          setPricePerUser(490);
        } else {
          setSubscriptionPlan(null);
        }
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [stores.companyUserStore.companyUserInvites]);

    const cancel = () => {
      ReactGA.event({
        category: "company_users",
        action: "discard_invite_new_user",
        label: "discard_invite_new_user_button",
      });
      stores.companyUserStore.selectedCompanyUser = undefined;
      stores.companyUserStore.selectedCompanyUserInvite = undefined;
      stores.companyUserStore.isCompanyUserEditModalOpened = false;
    };

    const formik = useFormik({
      initialValues: {
        id: companyUserInvite?.id,
        email: companyUserInvite ? companyUserInvite.email : "",
        name: companyUserInvite ? companyUserInvite.name : "",
        user_role: companyUserInvite
          ? companyUserInvite.user_role
          : stores.userRoleStore.userRoles[0],
      },
      validationSchema: editUserSchema,
      onSubmit: async (values, { setSubmitting }) => {
        setSubmitting(true);
        try {
          if (stores.companyUserStore.selectedCompanyUserInvite) {
            const updatedInvite = {
              ...stores.companyUserStore.selectedCompanyUserInvite,
              name: values.name,
              email: values.email,
              role_id: values.user_role.id,
            };
            await stores.companyUserStore.updateCompanyUserInvite(
              updatedInvite
            );
          }
        } catch (ex) {
          console.error(ex);
        } finally {
          setSubmitting(false);
          cancel();
        }
      },
    });

    const isMemberRoleSelected = formik.values.user_role?.name === "member";

    const handlePayment = async () => {
      const errors = await formik.validateForm();
      if (Object.keys(errors).length > 0) {
        formik.setTouched({ name: true, email: true });
        return;
      }
      localStorage.setItem("pendingInvite", JSON.stringify(formik.values));
      localStorage.setItem("inviteFlow", "true");

      const priceId =
        subscriptionPlan === "monthly"
          ? process.env.REACT_APP_MONTHLY_PRICE_ID
          : subscriptionPlan === "annual"
          ? process.env.REACT_APP_YEARLY_PRICE_ID
          : null;
      if (!priceId) {
        console.error("No valid subscription plan selected");
        return;
      }
      const successUrl = `${process.env.REACT_APP_PUBLIC_URL}/success`;
      const cancelUrl = `${process.env.REACT_APP_PUBLIC_URL}/cancel`;

      const stripeProfile = stores.userStore.currentUser.stripeProfile;
      if (!stripeProfile || !stripeProfile.id) {
        throw new Error("Stripe profile ID is undefined.");
      }
      try {
        ReactGA.event({
          category: "company_users",
          action: "initiate_payment_invite",
          label: "go_to_payment_button",
        });
        const stripeSession = await createCheckoutSession(
          priceId,
          successUrl,
          cancelUrl,
          null,
          stripeProfile.id,
          1,
          formik.values.email
        );
        sessionStorage.setItem("stripeSessionId", stripeSession.id);
        window.location.href = stripeSession.url;
      } catch (error) {
        console.error("Error initiating stripe payment", error);
      }
    };

    return (
      <>
        <form
          id="kt_modal_add_user_form"
          className="form"
          onSubmit={formik.handleSubmit}
          noValidate
        >
          <div
            className="d-flex flex-column scroll-y me-n7 pe-7"
            id="kt_modal_add_user_scroll"
            data-kt-scroll="true"
            data-kt-scroll-activate="{default: false, lg: true}"
            data-kt-scroll-max-height="auto"
            data-kt-scroll-dependencies="#kt_modal_add_user_header"
            data-kt-scroll-wrappers="#kt_modal_add_user_scroll"
            data-kt-scroll-offset="300px"
          >
            {subscriptionPlan && (
              <div className="alert alert-info">
                <strong>Note:</strong> Your current plan is{" "}
                {subscriptionPlan === "monthly" ? "monthly" : "annual"}. Every
                additional user will be billed at{" "}
                <strong>
                  {pricePerUser + "$"}{" "}
                  {subscriptionPlan === "monthly" ? "per month" : "per year"}.
                </strong>{" "}
              </div>
            )}

            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">
                {i18n.CompanyUserEditModalForm.fullName}
              </label>
              <input
                placeholder="Full name"
                {...formik.getFieldProps("name")}
                type="text"
                name="name"
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0 ms-1",
                  {
                    "is-invalid": formik.touched.name && formik.errors.name,
                  },
                  {
                    "is-valid": formik.touched.name && !formik.errors.name,
                  }
                )}
                autoComplete="off"
              />
              {formik.touched.name && formik.errors.name && (
                <div className="fv-plugins-message-container ms-1">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.name}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="fv-row mb-7">
              <label className="required fw-bold fs-6 mb-2">
                {i18n.CompanyUserEditModalForm.email}
              </label>
              <input
                placeholder="Email"
                {...formik.getFieldProps("email")}
                value={
                  companyUserInvite
                    ? companyUserInvite.email
                    : formik.values.email
                }
                className={clsx(
                  "form-control form-control-solid mb-3 mb-lg-0 ms-1",
                  {
                    "is-invalid": formik.touched.email && formik.errors.email,
                  },
                  {
                    "is-valid": formik.touched.email && !formik.errors.email,
                  }
                )}
                type="email"
                name="email"
                autoComplete="off"
                disabled={companyUserInvite ? true : false}
              />
              {formik.touched.email && formik.errors.email && (
                <div className="fv-plugins-message-container ms-1">
                  <div className="fv-help-block">
                    <span role="alert">{formik.errors.email}</span>
                  </div>
                </div>
              )}
            </div>

            <div className="mb-7">
              <label className="required fw-bold fs-6 mb-5">Role</label>
              {stores.userRoleStore.userRoles.map((userRole, index) => {
                if (userRole.name === "admin") return null;
                return (
                  <div className="d-flex fv-row" key={index}>
                    <div className="form-check form-check-custom form-check-solid">
                      <input
                        className="form-check-input me-3"
                        {...formik.getFieldProps("role")}
                        name="role"
                        type="radio"
                        value={userRole.name}
                        id={userRole.name}
                        checked={
                          formik.values.user_role?.name === userRole.name
                        }
                        disabled={formik.isSubmitting}
                        onClick={() =>
                          formik.setFieldValue("user_role", userRole)
                        }
                      />
                      <label
                        className="form-check-label"
                        htmlFor={userRole.name}
                      >
                        <div className="fw-bolder text-gray-800">
                          {userRole.name.charAt(0).toUpperCase() +
                            userRole.name.slice(1)}
                        </div>
                      </label>
                    </div>
                    {stores.userRoleStore.userRoles.length - 1 > index ? (
                      <div className="separator separator-dashed my-5"></div>
                    ) : null}
                  </div>
                );
              })}
            </div>
          </div>

          <div className="text-center pt-15">
            <button
              type="reset"
              onClick={() => cancel()}
              className="btn btn-light me-3"
              data-kt-users-modal-action="cancel"
              disabled={formik.isSubmitting}
            >
              {i18n.CompanyUserEditModalForm.discard}
            </button>

            {stores.companyUserStore.selectedCompanyUserInvite ? (
              <button
                type="submit"
                className="btn btn-primary"
                data-kt-users-modal-action="submit"
                disabled={
                  formik.isSubmitting ||
                  !formik.touched ||
                  !isMemberRoleSelected
                }
                onClick={() =>
                  ReactGA.event({
                    category: "company_users",
                    action: "submit_invite_new_user",
                    label: "submit_invite_new_user_button",
                  })
                }
              >
                <span className="indicator-label">
                  {i18n.CompanyUserEditModalForm.update}
                </span>
                {formik.isSubmitting && (
                  <span className="indicator-progress">
                    {i18n.CompanyUserEditModalForm.pleaseWait}
                    <span className="spinner-border spinner-border-sm align-middle ms-2"></span>
                  </span>
                )}
              </button>
            ) : (
              <button
                type="button"
                className="btn btn-primary"
                disabled={
                  formik.isSubmitting ||
                  !formik.touched ||
                  !isMemberRoleSelected
                }
                onClick={handlePayment}
              >
                Go to Payment
              </button>
            )}
          </div>
        </form>

        {formik.isSubmitting && <CompanyUserListLoading />}
      </>
    );
  }
);
