import React from "react";
import { CustomUser } from "../../../../../models/CustomUser";
import stores from "../../../../../stores";

type Props = {
  customUser: CustomUser;
};

export class CompanyUserSubscriptionStatusCell extends React.Component<Props> {
  render() {
    const { customUser } = this.props;
    const isAdmin = customUser.role_id === 1;

    const allSubs = stores.userStore.adminSubscriptions;

    if (!allSubs || allSubs.length === 0) {
      return <span style={{ color: "red" }}>No Subscription</span>;
    }

    let hasSubscription = false;

    if (isAdmin) {
      hasSubscription = allSubs.some((sub) => {
        const invitedEmailRaw = sub.metadata?.invited_member_email;
        const invitedEmail =
          typeof invitedEmailRaw === "string"
            ? invitedEmailRaw.trim()
            : undefined;

        return invitedEmail === undefined || invitedEmail === "";
      });
    } else {
      const userEmail = customUser.email.toLowerCase();
      hasSubscription = allSubs.some((sub) => {
        const invitedEmail = sub.metadata?.invited_member_email?.toLowerCase();
        return invitedEmail === userEmail;
      });
    }

    return hasSubscription ? (
      <span className="badge badge-light-success fw-bolder">Active</span>
    ) : (
      <span className="badge badge-light-danger fw-bolder">
        Subscription Expired
      </span>
    );
  }
}
