/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { observer } from "mobx-react";
import analytics from "../../../../../../helpers/Analytics";
import { CustomUser } from "../../../../../models/CustomUser";
import stores from "../../../../../stores";
import { createCheckoutSession } from "../../../../../../helpers/api";

type Props = {
  customUser: CustomUser;
};

@observer
export class CompanyUserActionsCell extends React.Component<Props> {
  private handleDeleteInviteItem = () => {
    analytics.trackEvent("delete_user_invite_button_pressed", {
      category: "company_user_events",
      label: "delete_user_invite_button",
    });
    const { customUser } = this.props;
    const selectedUserInvite = stores.companyUserStore.companyUserInvites.find(
      (invite) => invite.id === customUser.id
    );
    stores.companyUserStore.selectedCompanyUserInvite = selectedUserInvite;
    stores.companyUserStore.isCompanyUserInviteDeleteModalOpened = true;
  };

  private handleDeleteItem = async () => {
    const { customUser } = this.props;

    if (customUser.role_id !== 1) {
      const userEmail = customUser.email.toLowerCase();
      const subToCancel = stores.userStore.adminSubscriptions.find((sub) => {
        const invitedEmail = sub.metadata?.invited_member_email?.toLowerCase();
        return invitedEmail === userEmail;
      });

      if (subToCancel) {
        try {
          const API_URL = process.env.REACT_APP_API_URL || "";
          const response = await fetch(`${API_URL}/cancel-subscription`, {
            method: "POST",
            headers: { "Content-Type": "application/json" },
            body: JSON.stringify({ subscriptionId: subToCancel.id }),
          });
          if (!response.ok) {
            throw new Error(`İptal işlemi başarısız oldu: ${response.status}`);
          }
        } catch (error) {
          console.error(
            "Error cancelling subscription for user:",
            customUser.email,
            error
          );
          alert("Abonelik iptali başarısız oldu. Lütfen tekrar deneyin.");
          return;
        }
      }
    }

    analytics.trackEvent("delete_company_user_button_pressed", {
      category: "company_user_events",
      label: "delete_company_user_button",
    });
    stores.companyUserStore.selectedCompanyUser =
      stores.companyUserStore.companyUsers.find(
        (item) => item.id === customUser.id
      );
    stores.companyUserStore.isCompanyUserDeleteModalOpened = true;
  };

  private handleRenewSubscription = async () => {
    const { customUser } = this.props;

    const adminSub = stores.userStore.adminSubscriptions.find((sub) => {
      const invitedEmail = sub.metadata?.invited_member_email;
      return invitedEmail === undefined || invitedEmail.trim() === "";
    });

    let priceId = "";
    if (
      adminSub &&
      adminSub.items &&
      adminSub.items.data &&
      adminSub.items.data.length > 0
    ) {
      const currentPriceId = adminSub.items.data[0].price.id;
      if (currentPriceId === process.env.REACT_APP_MONTHLY_PRICE_ID) {
        priceId = process.env.REACT_APP_MONTHLY_PRICE_ID || "";
      } else if (currentPriceId === process.env.REACT_APP_YEARLY_PRICE_ID) {
        priceId = process.env.REACT_APP_YEARLY_PRICE_ID || "";
      } else {
        console.error("Admin's subscription plan does not match known plans.");
        return;
      }
    } else {
      console.error("Admin subscription not found.");
      return;
    }

    const successUrl = `${process.env.REACT_APP_PUBLIC_URL}/success`;
    const cancelUrl = `${process.env.REACT_APP_PUBLIC_URL}/cancel`;
    try {
      analytics.trackEvent("renew_subscription_button_pressed", {
        category: "company_user_events",
        label: "renew_subscription_button",
      });
      const stripeProfile = stores.userStore.currentUser.stripeProfile;
      if (!stripeProfile || !stripeProfile.id) {
        throw new Error("Stripe profile ID is undefined.");
      }
      const stripeSession = await createCheckoutSession(
        priceId,
        successUrl,
        cancelUrl,
        null,
        stripeProfile.id,
        1,
        customUser.email
      );
      sessionStorage.setItem("stripeSessionId", stripeSession.id);
      window.location.href = stripeSession.url;
    } catch (error) {
      console.error("Error initiating stripe payment", error);
    }
  };

  render() {
    const { customUser } = this.props;
    const companyUser = stores.companyUserStore.companyUsers.find(
      (user) => user.id === customUser.id
    );
    const isUserInCompanyUsers = stores.companyUserStore.companyUsers.some(
      (user) => user.id === customUser.id
    );

    const allSubs = stores.userStore.adminSubscriptions;
    let hasSubscription = false;
    if (allSubs && allSubs.length > 0) {
      if (customUser.role_id === 1) {
        hasSubscription = allSubs.some((sub) => {
          const invitedEmailRaw = sub.metadata?.invited_member_email;
          const invitedEmail =
            typeof invitedEmailRaw === "string"
              ? invitedEmailRaw.trim()
              : undefined;
          return invitedEmail === undefined || invitedEmail === "";
        });
      } else {
        const userEmail = customUser.email.toLowerCase();
        hasSubscription = allSubs.some((sub) => {
          const invitedEmail =
            sub.metadata?.invited_member_email?.toLowerCase();
          return invitedEmail === userEmail;
        });
      }
    }

    return (
      <div className="d-flex justify-content-end align-items-center">
        {customUser.role_id !== 1 && (
          <button
            type="button"
            className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm me-2"
            onClick={this.handleRenewSubscription}
            disabled={hasSubscription}
          >
            <span className="fa fa-sync fs-6 text-gray-600 me-1"></span>
          </button>
        )}

        {companyUser?.user.id.toString() ===
        stores.userStore.currentUser.id.toString() ? (
          <div></div>
        ) : !isUserInCompanyUsers ? (
          <div className="d-flex justify-content-end">
            <div className="d-flex justify-content-end flex-shrink-0">
              <button
                type="button"
                onClick={this.handleDeleteInviteItem}
                className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
              >
                <span className="fa fa-trash fs-6 text-gray-600 me-1"></span>
              </button>
            </div>
          </div>
        ) : (
          <div className="d-flex justify-content-end flex-shrink-0">
            <button
              type="button"
              onClick={this.handleDeleteItem}
              className="btn btn-icon btn-bg-light btn-active-color-primary btn-sm"
            >
              <span className="fa fa-trash fs-6 text-gray-600 me-1"></span>
            </button>
          </div>
        )}
      </div>
    );
  }
}
