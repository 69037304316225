import React from "react";
import * as Yup from "yup";
import { Formik } from "formik";
import { observer } from "mobx-react";
import { toast } from "react-toastify";
import "react-datepicker/dist/react-datepicker.css";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
// import DatePicker from "react-datepicker";

import { deleteFile, uploadFile } from "../../../../helpers/api/fileHelper";
import { createApiKey, updateUser } from "../../../../helpers/api";
import i18n from "../../../../i18n";
import { User } from "../../../models/User";
import { FeatureType } from "../../../../helpers/Enums";
import analytics from "../../../../helpers/Analytics";
import stores from "../../../stores";

import { AccountLoading } from "./loading/AccountLoading";
import GenerateAPIKeyModal from "./GenerateAPIKeyModal";
import { KTSVG } from "../../../../helpers";

interface IProfileDetails {
  picture: string;
  fName: string;
  phone: string;
}

interface Props {
  onShowDeleteModal: () => void;
}

interface State {
  data: {
    picture: string;
    fName: string;
    email: string;
    company: string;
    phone: string;
  };
  loading: boolean;
  apiKey: string;
  apiKeyGenerating: boolean;
  apiKeyModalIsVisible: boolean;
  showNotificationModal: boolean;
  useAutoSync: boolean;
  receiveEmailNotifications: boolean;
  canUseAutoSync: boolean;
  selectedFile: File | null;
  previewUrl: string | null;
  autoSyncTime: Date | null;
  showModal: boolean;
  modalMessage: string;
  togglesLoading: boolean;
  showDeleteModal: boolean;
}

const CLOUDFLARE_DOMAIN = "imagedelivery.net";

const profileDetailsSchema = Yup.object().shape({
  fName: Yup.string().required("First name is required"),
});

@observer
export default class Settings extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);

    const currentUser = stores.userStore.currentUser;
    const settings = currentUser.settings || {};
    const isFreeUser = stores.userStore.isFreeUser;

    this.state = {
      data: {
        picture: `${currentUser.picture}`,
        fName: `${currentUser.name}`,
        email: `${currentUser.email}`,
        company: `${stores.companyStore.selectedUserCompany?.name}`,
        phone: `${
          currentUser.phone_number ? currentUser.phone_number : "Phone Number"
        }`,
      },
      loading: false,
      apiKey: "",
      apiKeyGenerating: false,
      apiKeyModalIsVisible: false,
      showNotificationModal: false,
      useAutoSync: settings.is_auto_sync_active || false,
      receiveEmailNotifications: settings.is_notifications_active || false,
      canUseAutoSync: true,
      selectedFile: null,
      previewUrl: null,
      autoSyncTime: this.convertUTCToLocalTime(
        settings.auto_sync_time ? settings.auto_sync_time : "00:00"
      ),
      showModal: false,
      modalMessage: "",
      togglesLoading: !isFreeUser,
      showDeleteModal: false,
    };
    if (!isFreeUser) {
      this.initializeFeatureToggles();
    }
  }

  handleShowApiKeyModal = () => {
    this.setState({ apiKeyModalIsVisible: true });
  };

  handleCloseApiKeyModal = () => {
    this.setState({ apiKeyModalIsVisible: false });
  };

  handleShowNotificationModal = (message: string) => {
    this.setState({ showNotificationModal: true, modalMessage: message });
  };

  handleCloseNotificationModal = () => {
    this.setState({ showNotificationModal: false, modalMessage: "" });
  };

  private async initializeFeatureToggles() {
    const isFreeUser = stores.userStore.isFreeUser;

    if (!isFreeUser) {
      const canUseAutoSync = await stores.userStore.checkSubscribedFeatureType(
        FeatureType.AutoSync
      );

      this.setState({
        canUseAutoSync,
        togglesLoading: false,
      });
    } else {
      this.setState({
        togglesLoading: false,
      });
    }
  }

  handleTimeChange = (date: Date | null) => {
    if (date) {
      this.setState({ autoSyncTime: date });
    }
  };

  convertToUTCTime = (localTime: Date): string => {
    const utcTime = new Intl.DateTimeFormat("en-US", {
      timeZone: "UTC",
      hour: "2-digit",
      minute: "2-digit",
      hour12: false,
    }).format(localTime);

    return utcTime;
  };

  convertUTCToLocalTime = (utcHour: string) => {
    let currentDate = new Date();
    const hour = parseInt(utcHour.split(":")[0]);
    const minutes = parseInt(utcHour.split(":")[1]);

    currentDate.setUTCHours(hour, minutes, 0, 0);

    return currentDate;
  };

  handleSubmit = async (values: Partial<IProfileDetails>): Promise<void> => {
    this.setState({ loading: true });

    const {
      id,
      email,
      email_verified,
      gender,
      locale,
      phone_number_verified,
      is_onboarded,
      auth0_id,
      created_at,
    } = stores.userStore.currentUser;

    const utcTime = this.state.useAutoSync
      ? this.convertToUTCTime(this.state.autoSyncTime!)
      : null;

    const settings: any = {
      is_notifications_active: this.state.receiveEmailNotifications,
      is_auto_sync_active: this.state.useAutoSync,
      auto_sync_time: utcTime,
    };

    let newPicture = this.state.data.picture;

    if (this.state.selectedFile) {
      const oldImageUrl = this.state.data.picture;

      if (oldImageUrl.includes(CLOUDFLARE_DOMAIN)) {
        const isDeleted = await deleteFile(oldImageUrl);
        if (!isDeleted) {
          console.error("Old image could not be deleted.");
        }
      }

      const uploadedImageUrl = await uploadFile(this.state.selectedFile);
      if (uploadedImageUrl) {
        newPicture = uploadedImageUrl;
      } else {
        console.error("Failed to upload new image.");
      }
    }

    const updatedUser: User = {
      id,
      name: values.fName,
      email,
      email_verified,
      gender,
      locale,
      picture: newPicture,
      phone_number: values.phone,
      phone_number_verified,
      settings,
      is_onboarded,
      auth0_id,
      created_at,
    };

    try {
      const updateUserResult = await updateUser(updatedUser);

      if (updateUserResult) {
        stores.userStore.currentUser.name = updatedUser.name;
        stores.userStore.currentUser.phone_number = updatedUser.phone_number;
        stores.userStore.currentUser.picture = updatedUser.picture;
        stores.userStore.currentUser.settings = updatedUser.settings;

        await stores.companyUserStore.getCompanyUsers();

        this.handleShowNotificationModal(
          "Changes have been successfully saved."
        );

        this.setState({ loading: false });
      } else {
        this.handleShowNotificationModal("Failed to save changes.");

        this.setState({ loading: false });
      }
    } catch (error) {
      console.error("An error occurred:", error);
      this.handleShowNotificationModal(
        "An error occurred while saving changes."
      );
      this.setState({ loading: false });
    }
  };

  toggleEmailNotifications = () => {
    this.setState((prevState) => ({
      receiveEmailNotifications: !prevState.receiveEmailNotifications,
    }));
  };

  toggleAutoSync = () => {
    this.setState((prevState) => ({
      useAutoSync: !prevState.useAutoSync,
    }));
  };

  generateAPIKey = async () => {
    const company = stores.companyStore.selectedUserCompany;
    const currentUser = stores.userStore.currentUser;

    analytics.trackEvent("api_key_generated_button_pressed", {
      category: "user_profile_events",
      label: "generate_api_key_button",
    });

    if (!company) {
      toast.error(i18n.ToastMessages.companyNotFoundError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    const hasSubscription = await stores.userStore.checkUserSubscription();
    if (!hasSubscription) {
      toast.error(i18n.ToastMessages.subscriptionRequiredError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    if (!company.is_api_available) {
      toast.error(i18n.ToastMessages.apiKeyFeatureContactUs, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
      return;
    }

    this.setState({ apiKeyGenerating: true });

    const userId = currentUser.id;
    const subscriptionId = currentUser.subscription?.id || "";

    try {
      const result = await createApiKey(
        Number(company.id),
        Number(userId),
        subscriptionId
      );

      if (result && result.success) {
        this.setState({
          apiKey: result.apiKey || "",
          apiKeyModalIsVisible: true,
          apiKeyGenerating: false,
        });
      } else {
        this.setState({ apiKeyGenerating: false });
        toast.error(i18n.ToastMessages.generateApiKeyError, {
          position: "top-center",
          autoClose: 5000,
          hideProgressBar: false,
          closeButton: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "light",
        });
      }
    } catch (error) {
      console.error("API Key Generation Error:", error);
      this.setState({ apiKeyGenerating: false });
      toast.error(i18n.ToastMessages.generateApiKeyError, {
        position: "top-center",
        autoClose: 5000,
        hideProgressBar: false,
        closeButton: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "light",
      });
    }
  };

  render() {
    const {
      data,
      loading,
      togglesLoading,
      apiKeyGenerating,
      apiKey,
      apiKeyModalIsVisible,
      receiveEmailNotifications,
      previewUrl,
      modalMessage,
      showNotificationModal,
    } = this.state;

    console.log(
      "stores.companyStore.selectedUserCompany?.is_fine_tuned_model_active: ",
      stores.companyStore.selectedUserCompany?.is_fine_tuned_model_active
    );

    const isAdmin = stores.userStore.isCurrentUserAdmin;
    const isFreeUser = stores.userStore.isFreeUser;
    const company = stores.companyStore.selectedUserCompany;
    const isApiAvailable =
      stores.companyStore.selectedUserCompany?.is_api_available;

    if (loading || togglesLoading) {
      return (
        <div className="card mb-5 mb-xl-10">
          <div
            className="d-flex justify-content-center align-items-center"
            style={{ height: "50vh" }}
          >
            <AccountLoading />
          </div>
        </div>
      );
    }

    return (
      <div className="card mb-5 mb-xl-10">
        <div
          className="card-header border-0 cursor-pointer"
          role="button"
          data-bs-toggle="collapse"
          aria-expanded="true"
          aria-controls="kt_account_profile_details"
        >
          <div className="card-title m-0">
            <h3 className="fw-bolder m-0">
              {i18n.ProfileDetailsSettings.title}
            </h3>
          </div>
        </div>

        {apiKeyModalIsVisible && company ? (
          <GenerateAPIKeyModal
            apiKey={apiKey}
            onClose={() =>
              this.setState({
                apiKey: "",
                apiKeyGenerating: false,
                apiKeyModalIsVisible: false,
              })
            }
          />
        ) : null}

        <Modal
          show={showNotificationModal}
          onHide={this.handleCloseNotificationModal}
        >
          <Modal.Header closeButton>
            <Modal.Title>Notification</Modal.Title>
          </Modal.Header>
          <Modal.Body>{modalMessage}</Modal.Body>
          <Modal.Footer>
            <Button
              variant="primary"
              onClick={this.handleCloseNotificationModal}
            >
              Close
            </Button>
          </Modal.Footer>
        </Modal>

        <div id="kt_account_profile_details" className="collapse show">
          {isAdmin ? (
            <Formik
              initialValues={this.state.data}
              enableReinitialize={true}
              validationSchema={profileDetailsSchema}
              onSubmit={async (values, { resetForm }) => {
                await this.handleSubmit(values);

                this.setState({
                  data: { ...this.state.data, fName: values.fName },
                });
                resetForm({ values: this.state.data });
              }}
            >
              {(formik) => (
                <form
                  onSubmit={formik.handleSubmit}
                  noValidate
                  className="form"
                >
                  <div className="card-body border-top p-9">
                    {/* Avatar Section */}
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        {i18n.ProfileDetailsSettings.avatarLabel}
                      </label>
                      <div className="col-lg-8">
                        <div
                          className="image-input image-input-outline"
                          data-kt-image-input="true"
                          style={{
                            backgroundImage: `url(${
                              previewUrl || data.picture
                            })`,
                          }}
                        >
                          <div
                            className="image-input-wrapper w-125px h-125px"
                            style={{
                              backgroundImage: `url(${
                                previewUrl || data.picture
                              })`,
                            }}
                          ></div>
                          <input
                            className="mt-4"
                            type="file"
                            onChange={(e) => {
                              const file = e.target.files
                                ? e.target.files[0]
                                : null;
                              if (file) {
                                this.setState({
                                  selectedFile: file,
                                  previewUrl: URL.createObjectURL(file),
                                });
                                formik.setFieldValue("picture", file);
                              }
                            }}
                            accept="image/*"
                            onClick={() =>
                              analytics.trackEvent(
                                "profile_picture_updated_button_pressed",
                                {
                                  category: "user_profile_events",
                                  label: "profile_picture_updated_button",
                                }
                              )
                            }
                          />
                        </div>
                      </div>
                    </div>

                    {/* Full Name Section */}
                    <div className="row mb-6">
                      <label className="col-lg-4 col-form-label required fw-bold fs-6">
                        {i18n.ProfileDetailsSettings.fullNameLabel}
                      </label>

                      <div className="col-lg-8 fv-row">
                        <input
                          type="text"
                          className="form-control form-control-lg form-control-solid w-450px"
                          placeholder="Full Name"
                          {...formik.getFieldProps("fName")}
                        />
                        {formik.touched.fName && formik.errors.fName && (
                          <div className="fv-plugins-message-container">
                            <div className="fv-help-block">
                              {formik.errors.fName}
                            </div>
                          </div>
                        )}
                      </div>
                    </div>

                    {/* Receive Email Notifications */}
                    <div className="row mb-6 d-flex align-items-center">
                      <label className="col-lg-4 col-form-label fw-bold fs-6">
                        Receive Email Notifications
                      </label>

                      <div className="col-lg-8 fv-row">
                        <div className="form-check form-switch">
                          <input
                            className="form-check-input"
                            type="checkbox"
                            id="receiveEmailNotifications"
                            checked={receiveEmailNotifications}
                            onChange={() => {
                              this.toggleEmailNotifications();
                              formik.setFieldValue(
                                "receiveEmailNotifications",
                                !receiveEmailNotifications
                              );
                            }}
                            onClick={() =>
                              analytics.trackEvent(
                                "email_notifications_toggled",
                                {
                                  category: "user_profile_events",
                                  label: "toggle_email_notifications_button",
                                }
                              )
                            }
                          />
                          <label
                            className="form-check-label"
                            htmlFor="receiveEmailNotifications"
                          >
                            {receiveEmailNotifications ? "Enabled" : "Disabled"}
                          </label>
                        </div>
                      </div>
                    </div>

                    {/* Feature Toggles */}
                    {stores.companyStore.selectedUserCompany &&
                    stores.companyStore.selectedUserCompany
                      .is_fine_tuned_model_active ? (
                      <div className="row mb-6 d-flex align-items-center">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          Custom Model Status
                        </label>

                        <span
                          className="d-block pt-1"
                          style={{ flex: 1, alignItems: "end" }}
                        >
                          {stores.companyStore.selectedUserCompany
                            .fine_tuning_progress &&
                          stores.companyStore.selectedUserCompany
                            .fine_tuning_progress > 0 &&
                          stores.companyStore.selectedUserCompany
                            .fine_tuning_progress < 100 ? (
                            <span className="badge p-4 fs-6 badge-light-warning text-warning fw-bolder">
                              Training..
                            </span>
                          ) : stores.companyStore.selectedUserCompany
                              .fine_tuning_progress &&
                            stores.companyStore.selectedUserCompany
                              .fine_tuning_progress === 100 ? (
                            <span className="badge badge-light-primary p-4 text-primary fs-6 fw-bolder">
                              Trained
                            </span>
                          ) : (
                            <span className="badge p-4 fs-6 badge-light-danger text-danger fw-bolder">
                              Failed
                            </span>
                          )}

                          {stores.companyStore.selectedUserCompany
                            .fine_tuning_progress &&
                          stores.companyStore.selectedUserCompany
                            .fine_tuning_progress === 100 ? (
                            <button
                              className="btn btn-flex flex-center bg-light-info btn-color-gray-700 btn-active-color-primary w-40px w-md-auto h-40px px-0 px-md-6 ms-4"
                              onClick={() => {
                                stores.companyModelStore.trainCustomModel();
                              }}
                            >
                              <KTSVG
                                path={"/media/icons/duotune/arrows/arr029.svg"}
                                className="svg-icon svg-icon-x text-gray me-3"
                              />
                              <span className="d-none d-md-inline">Train</span>
                            </button>
                          ) : null}
                        </span>
                      </div>
                    ) : null}

                    {/* API Key Section */}
                    {!isFreeUser && company && (
                      <div className="row mb-6 d-flex align-items-center">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          API Key
                        </label>

                        <div className="col-lg-8 fv-row">
                          <button
                            type="button"
                            className="btn btn-lg btn-light-primary"
                            disabled={apiKeyGenerating}
                            onClick={() => {
                              if (!company.is_api_available) {
                                toast.error(
                                  i18n.ToastMessages.apiKeyFeatureContactUs,
                                  {
                                    position: "top-center",
                                    autoClose: 5000,
                                    hideProgressBar: false,
                                    closeButton: true,
                                    pauseOnHover: true,
                                    draggable: true,
                                    progress: undefined,
                                    theme: "light",
                                  }
                                );
                              } else {
                                this.generateAPIKey();
                              }
                            }}
                          >
                            {!apiKeyGenerating
                              ? `${i18n.ProfileDetailsSettings.generate}`
                              : `${i18n.ProfileDetailsSettings.pleaseWait}`}
                          </button>
                        </div>
                      </div>
                    )}

                    {/* Integration Section */}
                    {!isFreeUser && company && (
                      <div className="row mb-6 d-flex align-items-center">
                        <label className="col-lg-4 col-form-label fw-bold fs-6">
                          Integration
                        </label>

                        <div className="col-lg-8 fv-row">
                          <a
                            href="https://slack.com/oauth/v2/authorize?client_id=5824836852406.7825566892903&scope=commands,im:history,im:read,im:write,chat:write&user_scope="
                            onClick={() =>
                              analytics.trackEvent("add_to_slack_clicked", {
                                category: "user_profile_events",
                                label: "add_to_slack_button",
                              })
                            }
                          >
                            <img
                              alt="Add to Slack"
                              height="40"
                              width="139"
                              src="https://platform.slack-edge.com/img/add_to_slack.png"
                              srcSet="https://platform.slack-edge.com/img/add_to_slack.png 1x, https://platform.slack-edge.com/img/add_to_slack@2x.png 2x"
                            />
                          </a>
                        </div>
                      </div>
                    )}
                  </div>

                  <div className="card-footer d-flex justify-content-between py-6 px-9">
                    {isAdmin && (
                      <button
                        type="button"
                        className="btn btn-danger"
                        onClick={this.props.onShowDeleteModal}
                      >
                        Delete Account
                      </button>
                    )}
                    <button
                      type="submit"
                      className="btn btn-primary"
                      disabled={!formik.dirty || loading}
                      onClick={() =>
                        analytics.trackEvent(
                          "profile_settings_saved_button_pressed",
                          {
                            category: "user_profile_events",
                            label: "save_profile_settings_button",
                          }
                        )
                      }
                    >
                      {!loading
                        ? `${i18n.ProfileDetailsSettings.saveChanges}`
                        : `${i18n.ProfileDetailsSettings.pleaseWait}`}
                    </button>
                  </div>
                </form>
              )}
            </Formik>
          ) : (
            <div className="card-body border-top p-9">
              {!isAdmin && isApiAvailable && !isFreeUser && company && (
                <div className="row mb-6 d-flex align-items-center">
                  <label className="col-lg-4 col-form-label fw-bold fs-6">
                    API Key
                  </label>

                  <div className="col-lg-8 fv-row">
                    <button
                      type="button"
                      className="btn btn-lg btn-light-warning"
                      disabled={apiKeyGenerating}
                      onClick={() => {
                        if (!company.is_api_available) {
                          toast.error(
                            i18n.ToastMessages.apiKeyFeatureContactUs,
                            {
                              position: "top-center",
                              autoClose: 5000,
                              hideProgressBar: false,
                              closeButton: true,
                              pauseOnHover: true,
                              draggable: true,
                              progress: undefined,
                              theme: "light",
                            }
                          );
                        } else {
                          this.generateAPIKey();
                        }
                      }}
                    >
                      {!apiKeyGenerating
                        ? `${i18n.ProfileDetailsSettings.generate}`
                        : `${i18n.ProfileDetailsSettings.pleaseWait}`}
                    </button>
                  </div>
                </div>
              )}
            </div>
          )}
        </div>
      </div>
    );
  }
}
